import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link } from "react-router-dom";
import {
    handleLoginUser,
  } from "../../../store/actions/authAction";
  import { useNavigate } from "react-router-dom";
import "../Login.css";

function LoginMobile() {
    const timer = useRef();
    const [password, setPassword] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [error, setError] = useState("");
    const dispatch = useDispatch()
    var appInfo = useSelector((state) => state.appInfo);
    var registerUserInfo = useSelector((state) => state.registerUserInfo);
    console.log(registerUserInfo);
    const navigate= useNavigate()
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
      
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      useEffect(()=>{
        if(windowSize.width>=778){
            navigate("/auth");
        }

      },[windowSize.width])
      useEffect(()=>{
        if (appInfo.error!==null) {
          const timeoutId = setTimeout(() => {
            dispatch({type:'REMOVE_ERROR'})
          }, 1000);
    
          return () => clearTimeout(timeoutId);
        }
      },[appInfo.error])
    const handleLogin = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (phoneNumber === null || password === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let user = {
            phoneNumber: phoneNumber,
            password: password,
          };
          dispatch(
            handleLoginUser(user, (args) => {
              
              console.log("ARGS->", args);
              // setappInfo.loading(false);
              if (!args.user.id) {
                setError(appInfo.error);
                console.log("errorrrrrrrrrrrrrrrr");
              }
              else{
                // setappInfo.loading(false);
                navigate("/dashboard");
              }
            })
          );
        }
      };
  return (
    <Container className="bgImg" >
        <Row>
        <center>
            <Link to="/">
              <img style={{ width: 250,marginBottom:20,marginTop:40 }} src={whiteLogo} />
            </Link>
          </center>
        </Row>
        <Row style={{
             marginTop: 10,
             height:400,
             background: "rgba(216,216,216,0.48)",
             boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
             borderRadius: 5,
             backdropFilter: "blur(5px)",
             padding: 20,
             margin:5,
        }}>
            <Col md="10">
    <Form style={{ marginTop: 60 }}>
    <FormGroup style={{display:'flex',flexDirection:'column'}}>
      <input
        className="inputBox"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Email ID/ Mobile Number"
      />
      <input
        className="inputBox"
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        style={{ marginTop: 35 }}
        placeholder="Password"
      />
    </FormGroup>
     <center>
     <Button
      className="submitButton"
      style={{ marginTop: 32 }}
      onClick={() => handleLogin()}
    >
      {" "}
      {
        appInfo.loading?"Loading..." :"Sign In"
      }
       
    </Button>
    <p onClick={()=>navigate("/mobile-forget-password")} style={{marginTop:20,color:'#094709',cursor:'pointer'}}>Forgot Password?</p>
    <p onClick={()=>navigate("/mobile-register")} style={{marginTop:20,color:'#094709'}}>Don't have an account. <span style={{color:'#094709'}}>Register Now</span></p>
    {error !== "" ? (
        <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
        ) : null}

    {appInfo.error !== "" ? (
        <p style={{ color: "#9e1212", marginTop: 10 }}>
        {appInfo.error}
      </p>
    ) : null}
    </center>

  </Form>
            </Col>
            <center>
                  <p style={{width:'100%',marginTop:50, color:'#fff',fontWeight:400}}>2024 nCount. All rights reserved.</p>
                  </center>
        </Row>
    </Container>
  )
}

export default LoginMobile