import React from 'react'
import Footer from '../../Pages/Footer/Footer'
import Profile from '../Profile/Profile'
import Header from '../Header/Header'

function DashboardHome() {
  return (
    <>
    <Header/>
    <Profile/>
    <Footer/>
    </>
  )
}

export default DashboardHome