import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { handleGetAppUserSubmission, handleGetAppUserSubmissions } from '../../store/actions/odkAction';
import Dropdown from "../../components/customDropdown";
import moment from 'moment';
import {
Table,
Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import NcountTreeInventory from './ViewFormsUi.js/NcountTreeInventory';
import NcountTreeInventoryOrissa from './ViewFormsUi.js/NcountTreeInventoryOrissa';
import HimachalTreeSpecies from './ViewFormsUi.js/HimachalTreeSpecies';

function MyNCount() {
  const [selectedForm, setSelectedForm] = useState(null);
  const user = useSelector(state=>state.userInfo);
    const appInfo = useSelector(state=>state.appInfo);
    const [userSubmissions,setUserSubmissions] = useState(null);
    const [userSubmission,setUserSubmission] = useState(null);
    const [submissionInstanceId,setSubmissionInstanceId] = useState(null);
    console.log("user submission",userSubmissions)
    console.log(appInfo.loading,"loading")
    useEffect(()=>{
      dispatch({type:'DISABLE_LOADING'})
    },[])
   const allForms = [
    {
      id: 1,
      label: "nCount Tree Inventory Test",
      value:"nCount Tree Inventory Test",
      formId: "tree_test_24",
      roleId: 2,
      projectId: 1,
    },
    {
      id: 2,
      label: "nCount Odisha Tree Inventory",
      value:"nCount Odisha Tree Inventory",
      formId: "tree_test_b_odiya",
      roleId: 2,
      projectId: 1,
    },
    {
      id: 3,
      label:"Himachal Pradesh Tree Species Distribution",
      value:"Himachal Pradesh Tree Species Distribution",
      formId: "himachal_resource_map",
      roleId: 2,
      projectId: 1,
    },
  ];
    const dispatch=useDispatch()
    const getAppuserSubmissions = (formId) => {
          setUserSubmissions(null)
          let data = {
            "projectId":1,
            "formId":formId,
            "odkUserId":user.odkUserId,
          };
          dispatch(
            handleGetAppUserSubmissions(data, (args) => {
              console.log("ARGS->", args);
              setUserSubmissions(args)
            })
          );
        }
        const fetchAppuserSubmission = (instanceId) => {
          let data = {
            "projectId":1,
            "formId":selectedForm.formId,
            "odkUserId":user.odkUserId,
            "instanceId": instanceId
          };
          dispatch(
            handleGetAppUserSubmission(data, (args) => {
              setSubmissionInstanceId(instanceId)
              console.log("ARGS->", args);
              setUserSubmission(args)
              
            })
          );
        }
        const inputStyle={
          borderRadius: "0px",
          boxShadow: "none",
          paddingLeft:0,
          border:'none',
          borderBottom: '1px solid #125912',
        }
        const placeholderStyle = {
          color: '#0a3b0a',
        };
        const handleChange = (selectedOption) => {
          setUserSubmission(null)
          setUserSubmissions(null)
           setSelectedForm(selectedOption)
           getAppuserSubmissions(selectedOption.formId)
         };
        const handleFetchSubmission = (instanceId)=>{
          setSubmissionInstanceId(instanceId)
         console.log(instanceId)
         setUserSubmission(null)
         fetchAppuserSubmission(instanceId)

        }
        console.log("instance id",submissionInstanceId)
        console.log(userSubmission,userSubmissions?.length)
  return (
    <>
    <Dropdown inputStyle={inputStyle} placeholderStyle={placeholderStyle} placeholder={"Select Form"} options={allForms} onChange={handleChange} />
   <div style={{marginTop:35}}>
   <center>{(appInfo.loading && userSubmissions===null)?<p>Loading....</p>:null}</center> 
   {
     (userSubmissions && userSubmissions?.length>0)&&(
      <>
     <Table size="sm">
  <thead>
    <tr>
      
      <th style={{fontWeight:500,fontSize:14}}>
        Submitted at
      </th>
      <th style={{fontWeight:500,fontSize:14}}>
        Action
      </th>
    </tr>
  </thead>
  <tbody>
  {
     userSubmissions.map((submission)=>{
      return (
    <tr>
       
      <td style={{fontSize:14,fontStyle:'italic',padding:10}}>
        {moment(submission.createdAt).format('Do MMMM YYYY, h:mm:ss a')}
      </td>
        
      <td style={{padding:5}}>
       <Button size='sm' style={{background:'#124912'}} onClick={()=>handleFetchSubmission(submission.instanceId)}>View</Button>
      </td>
    </tr>
        )
      })
    }
  </tbody>
</Table>

    </>
    )
  }
   <center>{(appInfo.loading && userSubmissions)?<p>Loading....</p>:null}</center> 
   {
     (userSubmissions && userSubmissions?.length===0)&&
     <center>
      <p>No submission has been made yet.</p>
      </center>
   }
   {
    (selectedForm?.formId==='tree_test_24' && userSubmission) &&
    <NcountTreeInventory userSubmission={userSubmission} selectedForm={selectedForm} submissionInstanceId={submissionInstanceId} />
   }
     {
    (selectedForm?.formId==='tree_test_b_odiya' && userSubmission) &&
    <NcountTreeInventoryOrissa userSubmission={userSubmission} selectedForm={selectedForm} submissionInstanceId={submissionInstanceId} />
   }
   {
    (selectedForm?.formId==='himachal_resource_map' && userSubmission) &&
    <HimachalTreeSpecies userSubmission={userSubmission} selectedForm={selectedForm} submissionInstanceId={submissionInstanceId} />
   }
   </div>
    </>
  )
}

export default MyNCount