import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cameraIcon from "../../assets/Icons/camera.png";
import leafIcon from "../../assets/Icons/Leaf.png";
import editIcon from "../../assets/Icons/edit.png";
import "./Profile.css";
import {
  Button,
  Tooltip ,
  NavItem,
  TabPane,
  NavLink,
  Nav,
  TabContent,
  Container,
  Row,
  Col,
} from "reactstrap";
import MyStory from "./MyStory";
import CollectionDetails from "./CollectionDetails";
import Dropdown from "../../components/customDropdown";
import MyNCount from "./My-nCount";
import Instructions from "./Instructions";
import { getQR } from "../../components/GenerateQR";
import {Form,FormGroup,Input} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { handleGetProfile, handleUpdateProfileImage,handleUpdateProfile } from "../../store/actions/profileAction";
import { handleCreateOdkUser, handleGetAppUserSubmissions, handleRequestFormAccess } from "../../store/actions/odkAction";
import StartCollection from "./ViewFormsUi.js/StartCollection";
import locationIcon from '../../assets/Icons/location.png';
import institutionsIcon from '../../assets/Icons/institutions.png'
import webIcon from '../../assets/Icons/web.png'
import mailIcon from '../../assets/Icons/mail.png'
import axios from "axios";
const useHiddenFileInput = () => {
  const hiddenFileInput = useRef(null);

  return hiddenFileInput;
};

function Profile() {
  const [tabId, setTabId] = useState("4");
  const [uri, setUri] = useState("");
  const [base64, setBase64] = useState(null);
  const user = useSelector((state) => state.userInfo);
  const appInfo = useSelector((state) => state.appInfo);
  const [isHovered, setIsHovered] = useState(false);
  const whichTabIsActive = [false, false, false,true];
  const [tabActive, setTabActive] = useState(whichTabIsActive);
  const [editProfileTrue,setEditProfileTrue] = useState(false);
  const [name,setName] = useState(user.name || '');
  const [email,setEmail] = useState(user.email || '');
  const [address,setAddress] = useState(user.address || '')
  const [weburl,setWebUrl] = useState(user.webURL || '')
  const [contributions,setContributions] = useState(null);
  const [organization,setOrganization] = useState(user.organization || null)
  const [organizationName,setOrganizationName] = useState(user.organizationName || '')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{
    console.log("getting profile---------------->>>>>>>>>>>")
    let data = {
      token:user.authToken,
      phoneNumber:user.phoneNumber
    }
    dispatch(
      handleGetProfile(data, (args) => {
        console.log("ARGS->", args)
        if(args=="Forbidden"){
          dispatch({ type: "LOG_OUT"});
      navigate("/mobile-auth")
        }
      })
    );
  },[editProfileTrue])
  useEffect(()=>{
    handleGetUserContribution()
  },[])

   const handleGetUserContribution = async() =>{
   await axios.post('https://ml3nelydd5.execute-api.us-east-1.amazonaws.com/getcontributions',{
    odkUserId:user.odkUserId
   }).then(function (response){
    console.log(response)
    setContributions(response.data)
        // setContributions(response)
   })
   .catch(function (error){
    console.log(error);
   })
  }
   
  console.log("user------------------->>>>>>",user)
  const hiddenFileInput = useHiddenFileInput();
  const handleClick = () => {
    const event = hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("Select files", selectedFile);
    convertToBase64(selectedFile);
  };
  const convertToBase64 = (image) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    console.log("base64 started....");
    reader.onload = () => {
      setBase64(reader.result);
      uploadImage(reader.result)
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const uploadImage = async (base64Data) => {
    let data = {
      phoneNumber: user.phoneNumber,
      base64Data: base64Data,
      token:user.authToken,
    };
    dispatch(
      handleUpdateProfileImage(data, (args) => {
        console.log("ARGS->", args);
        setBase64(null);
      })
    );
  };

  const activeTab = (id) => {
    setTabId(id);
    var newtTabActive = [false,false, false, false,false];
    newtTabActive[id-1] = true;
    setTabActive(newtTabActive);
  };
  console.log("tab id",tabId)

  const generatingQRCode = async () => {
    let data = {
      displayName: user.name,
      projectId: 1,
      phoneNumber: user.phoneNumber,
    };
    dispatch(
      handleCreateOdkUser(data, (args) => {
        console.log("ARGS->", args);
        GenerateQR(args.token);
      })
    );
  };
  const GenerateQR = (token) => {
    setUri(getQR(token));
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const organizations = [
    { value: 'Community', label: 'Community' },
    { value: 'Government', label: 'Government' },
    { value: 'Academic and Research', label: 'Academic and Research' },
    { value: 'Civil Society', label: 'Civil Society' },
    { value: 'Corporations', label: 'Corporations' },
    { value: 'Others', label: 'Others' }
  ];
  const handleChange = (selectedOption) => {
     setOrganization(selectedOption.value)
   };
   const placeholderStyle = {
    color: '#0a3b0a',
    fontSize:organization!=''?16:14,
    fontWeight:400,
  };
  const inputStyle={
    borderRadius: "0px",
    boxShadow: "none",
    marginTop:20,
    fontWeight:400,
    color:'#0a3b0a',
    fontSize:15,
    border:'none',
    border: '0.1px solid #125912',
  }

  const updateProfile = () =>{
    console.log(user);
    let data = {
      token:user.authToken,
      name:name,
      phoneNumber:user.phoneNumber,
        email:email,
        organization:organization,
        organizationName:organizationName,
        address:address,
      webURL:weburl
    };
    dispatch(
      handleUpdateProfile(data, (args) => {
        console.log("ARGS->", args);
        setEditProfileTrue(false);
      })
    );
  }

  return (
    <div style={{ marginBottom: 100, marginTop: 50 }}>
      <Container>
        <Row style={{}}>
          <Col md="3" sm="12" xs="12">
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
              onClick={handleClick}
               id="ProfilePicture"
                style={{ height: 190, position: "absolute" }}
                src={leafIcon}
              />

              <img
              onClick={handleClick}
              id="ProfilePicture"
                style={{
                  height: 120,
                  marginLeft:57,
                  marginTop: 65,
                  borderRadius: 100,
                  width: 120,
                  background: "#fff",
                  // border: "1px solid #607420",
                }}
                src={user.userImageUri}
              />

              <input
              id="ProfilePicture"
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
               <Tooltip
        isOpen={tooltipOpen}
        target="ProfilePicture"
        placement="bottom"
        toggle={toggle}
        style={{fontSize:13,background:'#124912',color:'white'}}
      >
        Change Profile Picture
      </Tooltip>
{!editProfileTrue ?
<>
              <p
              style={{
                marginTop: 15,
                fontSize: 22,
                // marginLeft:-20,
                fontWeight: 700,
                color: "#0a3b0a",
              }}
            >
              {user.name}
            </p>
            {user.organizationName &&
            <div style={{marginTop:10,display:'flex',alignItems:'center'}}>
              <img src={institutionsIcon} style={{height:'15px',width:'15px'}} />
              <p style={{margin:0,marginLeft:5}}>{user.organizationName}</p>
            </div>
}
{user.email &&
            <div style={{marginTop:10,display:'flex',alignItems:'center'}}>
              <img src={mailIcon} style={{height:'17px',width:'17px',marginTop:2}} />
              <p style={{margin:0,marginLeft:5,marginTop:0}}>{user.email}</p>
            </div>
}
{
  user.address &&
            <div style={{marginTop:10,display:'flex',alignItems:'center'}}>
              <img src={locationIcon} style={{width:'15px'}} />
              <p style={{margin:0,marginLeft:5}}>{user.address}</p>
            </div>
}
{
 user.webURL && 
            <div style={{marginTop:10,display:'flex',alignItems:'center'}}>
              <img src={webIcon} style={{height:'20px',width:'20px'}} />
              <a href={`${user.webURL}`} style={{textDecoration:'none',color:'black'}} target="_blank"><p style={{margin:0,marginLeft:5}}>{`${user.webURL}`}</p></a>
            </div>
}
            <br></br>
            <Button onClick={()=>setEditProfileTrue(true)} style={{background:'#c0810f',border:'none',borderRadius:8,width:'100%',fontSize:16}}>Edit Profile</Button>
</>:
<>
            <Input
         className="inputBox1"
        style={{ marginTop: 20 }}
        placeholder="Name"
        value={name}
      onChange={(e)=>setName(e.target.value)}
      />
       <Input
         className="inputBox1"
        style={{ marginTop: 20 }}
        placeholder="Email"
        value={email}
      onChange={(e)=>setEmail(e.target.value)}
      />
       
       <Dropdown inputStyle={inputStyle} options={organizations} placeholderStyle={placeholderStyle} placeholder={organization!=''? user.organization:"Choose organization"} onChange={handleChange}/>
       <Input
         className="inputBox1"
         style={{ marginTop: 20 }}
         placeholder="Name of Organization"
         value={organizationName}
       onChange={(e)=> setOrganizationName(e.target.value)}
       />
       <Input
         className="inputBox1"
         style={{ marginTop: 20 }}
         placeholder="Address"
         value={address}
       onChange={(e)=> setAddress(e.target.value)}
       />
       <Input
         className="inputBox1"
         style={{ marginTop: 20 }}
         placeholder="Digital Portfolio Link"
         value={weburl}
       onChange={(e)=>setWebUrl(e.target.value)}
       />
       <div>
       <Button onClick={updateProfile} style={{ backgroundColor: "#124912",fontSize:14,borderRadius:7}} size="sm" className="submitButton">
                  Save
                </Button>
                <Button onClick={()=>setEditProfileTrue(false)} style={{fontSize:14,borderRadius:7,marginLeft:10,background:'#c0810f'}} size="sm" className="submitButton">
                  Cancel
                </Button>
       </div>
       </>
}
            <br></br>
            <hr/>

            <p
              style={{
                marginTop: 13,
                fontSize: 22,
                fontWeight: 700,
                color: "#0a3b0a",
              }}
            >
              Access Credential
            </p>
            {
          !user.odkToken ? 
          <>
           
          <Button onClick={() => generatingQRCode()} style={{background:'#c0810f',border:'none',borderRadius:8,width:'100%',fontSize:16}}>
          {appInfo.loading ? "Generating Access Code..." : "Generate Access Code"}
        </Button>
          </>
        :null        
  }
  {uri != "" ? (
          <div>
            <center>
            <p style={{ marginTop: 10,fontSize:14 }}>Please scan it on your ODK Collect.</p>
            <img style={{ width: 200, height: 200 }} src={uri} alt="QR Code" />
            <p style={{ marginTop: 10,display:'flex',justifyContent:'space-evenly' }}>
              <a href={uri} download="nCount-ODK-QRCode.jpg">
                <Button style={{ backgroundColor: "#124912",fontSize:14}} size="sm" className="submitButton">
                  Download QR
                </Button>
              </a>
            <a href="https://play.google.com/store/apps/details?id=org.odk.collect.android&hl=en&gl=US" target="_blank">
                <Button size="sm" style={{marginLeft:5,fontSize:14,background:'#c0810f'}} className="submitButton">
                  Get ODK Collect
                </Button>
              </a>
              
            </p>
            </center>
          </div>
        ) : user.odkToken && (
          GenerateQR(user.odkToken)
        )}
            <hr/>
            <p
              style={{
                marginTop: 13,
                fontSize: 22,
                // marginLeft:-20,
                fontWeight: 700,
                color: "#0a3b0a",
              }}
            >
              Contributions
            </p>
            <p  style={{
                marginTop: 13,
                fontSize: 14,
            
              }}>{!contributions?
              <p>
              You have not made any contribution yet.</p>:<p style={{cursor:'pointer'}} onClick={()=>activeTab("3")}>You have made {contributions}  contributions.</p>}</p>
            </div>
          </Col>
          <Col md="1"></Col>
          <Col md="8" sm="12" xs="12">
            <div style={{ marginTop: 50 }}>
              <Nav tabs className="mob-tabs">
              <NavItem>
                  <NavLink
                    className={tabActive[3] ? "active" : ""}
                    onClick={() => activeTab("4")}
                    href="#"
                    style={{ color: "#0a3b0a", paddingLeft: 0 }}
                  >
                    <h6 style={{ fontWeight: 400 }}>&nbsp; &nbsp;Getting&nbsp;Started</h6>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={tabActive[0] ? "active" : ""}
                    onClick={() => activeTab("1")}
                    href="#"
                    style={{ color: "#0a3b0a", paddingLeft: 0 }}
                  >
                    <h6 style={{ fontWeight: 400 }}>&nbsp; &nbsp; &nbsp;My&nbsp;Story</h6>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                  disabled={user.odkUserId?false:true}
                    href="#"
                    className={tabActive[1] ? "active" : ""}
                    onClick={() => activeTab("2")}
                    style={{ color: "#0a3b0a" }}
                  >
                    <h6 style={{ fontWeight: 400 }}>&nbsp; &nbsp;Data&nbsp;Collection</h6>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    disabled={user.odkUserId?false:true}
                    href="#"
                    className={tabActive[2] ? "active" : ""}
                    onClick={() => activeTab("3")}
                    style={{ color: "#0a3b0a" }}
                  >
                    <h6 style={{ fontWeight: 400 }}>&nbsp; &nbsp;My&nbsp;nCount</h6>
                  </NavLink>
      
                </NavItem>
                <NavItem>
                <NavLink
                    href="#"
                    className={tabActive[4] ? "active" : ""}
                    onClick={() => activeTab("5")}
                    style={{ color: "#0a3b0a" }}
                  >
                    <h6 style={{ fontWeight: 400 }}>&nbsp; &nbsp;Data&nbsp;Analytics</h6>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent style={{ paddingTop: 20 }} activeTab={tabId}>
                {/* <TabPane tabId="1">
                  <MyStory/>
                </TabPane> */}
                <TabPane tabId="2" style={{ marginBottom: 100 }}>
                  <StartCollection />
                </TabPane>
                <TabPane tabId="3" style={{ marginBottom: 300 }}>
                  <MyNCount tabId={tabId} />
                </TabPane>
                <TabPane tabId="4" style={{ marginBottom: 300 }}>
                  <Instructions tabId={tabId} setActiveTab={activeTab}/>
                </TabPane>
                <TabPane tabId="5" style={{ marginBottom: 300 }}>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <p style={{fontWeight:500,color:'#0a3b0a'}}>Your Road To Action Is Under Construction!!</p>

                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Col>
          {/* <Col md="2"></Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Profile;
