import React from 'react'
import Header from '../Header/Header'
import AboutComp1 from './AboutComp1'
import Footer from '../Footer/Footer'

function About() {
  return (
    <>
    <Header/>
    <AboutComp1/>
    <Footer/>
    </>
  )
}

export default About