import React,{useState,useEffect} from "react";
import {
    Container,
    Input,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col
  } from "reactstrap";
  import axios from "axios";
  import { Link } from "react-router-dom";
import Dropdown from "../../components/customDropdown";

function NeedDiscoveryComp() {
    const [userName,setUsername] = useState('');
    const [fullName,setFullName] = useState('')
    const [country,setCountry] = useState('');
    const [sdg,setSDG] = useState('');
    const [extraDetail,setExtraDetails] = useState('')
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      console.log (windowSize)
      const sdgList = [
        { value: 'No Poverty', label: 'No Poverty' },
        { value: 'Zero Hunger', label: 'Zero Hunger' },
        { value: 'Good Health and Well-Being', label: 'Good Health and Well-Being' },
        { value: 'Quality Education', label: 'Quality Education' },
        { value: 'Gender Equality', label: 'Gender Equality' },
        { value: 'Clean Water and Sanitation', label: 'Clean Water and Sanitation' },
        { value: 'Affordable and Clean Energy', label: 'Affordable and Clean Energy' },
        { value: 'Decent Work and Economic Growth', label: 'Decent Work and Economic Growth' },
        { value: 'Industry, Innovation and Infrastructure', label: 'Industry, Innovation and Infrastructure' },
        { value: 'Reduced Inequalities', label: 'Reduced Inequalities' },
        { value: 'Sustainable Cities and Communities', label: 'Sustainable Cities and Communities' },
        { value: 'Responsible Consumption and Production', label: 'Responsible Consumption and Production' },
        { value: 'Climate Action', label: 'Climate Action' },
        { value: 'Life Below Water', label: 'Life Below Water' },
        { value: 'Life and Land', label: 'Life and Land' },
        { value: 'Peace, Justice and Strong Institutions', label: 'Peace, Justice and Strong Institutions' },
        { value: 'Partnerships For The Goals ', label: 'Partnerships For The Goals ' }
      ];
      const handleChange = (selectedOption) => {
         setSDG(selectedOption.value)
       };
      const placeholderStyle = {
        color: '#0a3b0a',
        fontSize:14,
        fontWeight:400,
      };
      const inputStyle={
        borderRadius: "4px",
        boxShadow: "none",
        fontWeight:400,
        color:'#0a3b0a',
        fontSize:15,
        border: '0.1px solid #125912',
      }    

      const handleSendRequest = async() =>{
         
         await axios.post('https://otddu6pngb.execute-api.us-east-1.amazonaws.com/measurement_gaps',{
           userName,fullName,country,sdg,extraDetail
         })
         .then((resp)=>{
          setCountry('');
          setExtraDetails('');
          setFullName('');
          setUsername('');
          console.log(resp)
         })
         .catch((err)=>{
          console.log(err);
         })
      }
      
  return (
    <>
    <Container style={{ background: "#0a3b0a", color: "#fff" }}>
    <Row>
      <Col md="3" sm="6" xs="12">
        <Link to="/" style={{ textDecoration: "none" }}>
          <p
            style={{
              marginTop: 10,
              marginBottom: 5,
              fontSize: 14,
              color: "#b7b5b2",
            }}
            >
            Home
          </p>
        </Link>
        <h2 className="title1" style={{ marginBottom: 15, fontSize: 35 }}>Need Discovery</h2>
      </Col>
      <Col></Col>
    </Row>
  </Container>
  <Container style={{marginBottom:50}}>
  <center>
            <h6 style={{marginTop:25,marginBottom:20,fontSize:20,color:'#054805'}}>Measurements for Sustainability Actions</h6>
        </center>
        <hr style={{marginBottom:20}}/>
        <Row>
            <Col md="8" style={{fontSize:14,paddingRight:25}}>
              
              <p style={{textAlign:'justify'}}>
              Understanding natural, physical, and social systems requires meticulous measurement and analysis of their constituent elements. However, the lack of longitudinal, 
              large-scale and high-frequency measurement information on these attributes poses a significant hindrance to the development of actionable knowledge. Measurement data serves as the cornerstone for deriving 
              actionable insights and facilitating evidence-based interventions. Moreover, high-frequency longitudinal measurement data enables trend analysis, pattern recognition, and the identification of emerging 
              challenges, thereby empowering stakeholders to proactively address impending crises. Digital revolution, sensor technology and related advances in their applications has emerged as a pivotal force in 
              advancing social-ecological research, encompassing satellite data, smartphone applications, and voluntary GIS initiatives. Through the participation of volunteers from diverse backgrounds, citizen science 
              initiatives contribute significantly to data collection efforts leading to seminal scientific reports. This large-scale data collection, spanning extensive geographic regions and extended time periods, 
              provides important insights into patterns and trends that would otherwise be challenging to achieve through traditional methods alone.
              </p>
              <p style={{textAlign:'justify'}}>
              Citizen science approaches have helped the research community discover useful insights and enhance the understanding of natural and social systems. However, the new knowledge produced by researchers using 
              contributions from citizens is often locked within research papers. The cryptic and esoteric nature of this knowledge prevents citizens from utilizing it in their local contexts. There is a pressing need to make research
               insights available to communities of citizens in a readily usable form. The availability of a platform to compile verified and gold-standard information in real time and produce actionable knowledge 
              promises to dramatically accelerate the translation of data collection and research into action and make sustainability interventions more effective.
              </p>
              <p style={{textAlign:'justify'}}>
              nCount consistently assesses measurement requirements and empowers technology-driven frameworks to meet them. If you identify a deficiency in data or information essential for any sustainability initiative, 
              please complete the form below outlining the specifics of the data/information gap. We will assess the gap, develop data capture and analytical pipelines, and provide actionable insights to support the achievement of sustainability objectives.
              </p>
            </Col>
            <Col md="4" >
                <Container style={{padding:25,boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',borderRadius:4}}>
              <p style={{fontSize:16,fontWeight:500,color:'#125912'}}>Suggest the Gap!</p>
            <Label style={{fontSize:13,marginTop:10,color:'#125912'}} for="email">
      Email / Mobile Number
    </Label>
            <Input
         className="inputBox1"
         style={{borderRadius:4}}
             value={userName}
      onChange={(e)=>setUsername(e.target.value)}
      />
      <Label style={{fontSize:13,marginTop:10,color:'#125912'}} for="name">
      Full Name
    </Label>
       <Input
         className="inputBox1"
         style={{borderRadius:4}}
             value={fullName}
           onChange={(e)=>setFullName(e.target.value)}
         />
        <Label style={{fontSize:13,marginTop:10,color:'#125912'}} for="name">
     Country
    </Label>
       <Input
         className="inputBox1"
         style={{borderRadius:4}}
         value={country}
         onChange={(e)=>setCountry(e.target.value)}
         />
      <Label style={{fontSize:13,marginTop:10,color:'#125912'}} for="name">
     Select the target Sustainable Development Goal (SDG)
    </Label>
    <Dropdown inputStyle={inputStyle} options={sdgList}  placeholder={''} onChange={handleChange}/>
       <Label style={{fontSize:13,marginTop:10,color:'#125912'}} for="name">
     Describe the data/information gap
    </Label>
       <Input
         className="inputBox1"
         style={{borderRadius:4}}
         type="textarea"
         value={extraDetail}
         onChange={(e)=>setExtraDetails(e.target.value)}
         />
      <center>

       <Button onClick={handleSendRequest}  style={{fontSize:14,borderRadius:7,marginLeft:10,background:'#c0810f'}} size="sm" className="submitButton">
                  Submit
                </Button>
      </center>
         </Container>
            </Col>
        </Row>
  </Container>

              </>
  )
}

export default NeedDiscoveryComp