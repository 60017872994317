import React from 'react'
import { motion } from "framer-motion";
import {Button } from "reactstrap";
import { Link } from "react-router-dom";
import './NotFound.css'

function NotFound() {
  return (
    <div className="not-found-container">
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="not-found-content"
    >
      <h1>404 - Page Not Found</h1>
      <p>Oops! Looks like you're lost.</p>
      <Link to='/'>
      <Button
                  className="submitButton"
                  >
                  {" "}
                  Go Back Home
                </Button>
                  </Link>
    </motion.div>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.9 }}
      transition={{ duration: 2, yoyo: Infinity }}
      className="background-animation"
    />
  </div>
  )
}

export default NotFound