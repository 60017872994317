import React, { useState, useEffect } from "react";
import "./Footer.css";
import { Container, Col, Row } from "reactstrap";
import OpenSourceIcon from "../../assets/Icons/OpenSource.png";
import footerLogo from "../../assets/Icons/FooterLogo.png";
import sdgLogo from "../../assets/logos/sdg logo.png";
import instaIcon from "../../assets/Icons/insta.png";
import linkendinIcon from "../../assets/Icons/linkendin.png";
import youtubeIcon from "../../assets/Icons/youtube.png";
import githubIcon from "../../assets/Icons/github.png";
import twitterIcon from "../../assets/Icons/twitter.png";
import { Link } from "react-router-dom";
function Footer() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(windowSize);
  return windowSize.width < 778 ? (
    <Container
      style={{ marginTop: 10, background: "rgb(10 59 10)", paddingBottom: 30 }}
    >
      <Row>
        <Col md="2" xs="6" sm="6" className="explore">
          <h6 style={{ color: "#c0cbc7" }}>Explore</h6>
          <h6 style={{ color: "#c0cbc7" }}>Understand</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/need-discovery'><p style={{marginBottom:11,color:'white'}}>Measurement Gap</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Data Governance</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Action Pipelines</p></Link>
          {/* <p>Bamboo Density Map</p>
            <p>Economic Potential Map</p>
            <p>Sustainable Extraction Map</p> */}
        </Col>
        <Col md="2" xs="6" sm="6" className="science">
          <h6 style={{ color: "#c0cbc7" }}>Science</h6>
          {/* <p>Regeneration and Recruitment Trends</p> */}
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Publications</p></Link>
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}> <p style={{marginBottom:11,color:'white'}}>Request Data</p></Link>
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Sustainability Impacts</p></Link>
          
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Gaia API</p></Link>
          
   
        </Col>
        <Col md="2" xs="6" sm="6" className="about">
          <h6 style={{ color: "#c0cbc7" }}>About</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Team</p></Link>
          {/* <p style={{marginBottom:11,color:'white'}}>Resources</p> */}
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>nCount Intiative</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Opportunities</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Contact</p></Link>
        </Col>
        <Col md="2" xs="6" sm="6" className="help">
          <h6 style={{ color: "#c0cbc7" }}>Help</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Getting started with nCount</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Frequently Asked Questions</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Data Sharing Policy</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}> <p style={{marginBottom:11,color:'white'}}>Privacy Policy</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Terms of Use</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='https://forum.ncount.in/' target="_blank"><p style={{marginBottom:11,color:'white'}}>Discussion Forum</p></Link>
        </Col>
        <Col
          md="2"
          xs="12"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: 90, marginRight: 10 }}
            className="openSourceIcon"
            src={sdgLogo}
          />
          <img
            style={{ width: 80 }}
            className="openSourceIcon"
            src={OpenSourceIcon}
          />
        </Col>
        <Col
          md="2"
          xs="12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          <a href="https://www.instagram.com/ncount_iofe/" target="_blank">
            <img src={instaIcon} style={{ width: 30 }} />
          </a>
          &nbsp;
          <a href="https://www.youtube.com/@nCount-IOFE/" target="_blank">
            <img src={youtubeIcon} style={{ width: 37 }} />
          </a>
          <a href="https://github.com/Forest-Economy-Alliance" target="_blank">
            <img src={githubIcon} style={{ width: 35 }} />
          </a>
          &nbsp;
          <a
          href="https://twitter.com/ncountforum" target="_blank"
            >
              <img src={twitterIcon} style={{ width: 30}} />
            </a>
            &nbsp;
          <a href="linkedin.com/in/ncount-platform-for-sustainability-action-856679303" target="_blank">
            <img src={linkendinIcon} style={{ width: 35 }} />
          </a>
        </Col>
        <Col md="1"></Col>
      </Row>
      <hr style={{ height: 1, color: "white" }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ color: "#d6c9c9", fontSize: 13, fontWeight: 300 }}>
          2024 nCount.All rights reserved
        </p>
        <img style={{ width: 30 }} src={footerLogo} />
      </div>
    </Container>
  ) : (
    <Container
      style={{ marginTop: 10, background: "rgb(10 59 10)", paddingBottom: 30 }}
    >
      <Row
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: 25,
          color: "#c0cbc7",
        }}
      >
        <div className="explore" style={{ width: "20%" }}>
          <h6 style={{ color: "#c0cbc7" }}>Understand</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/need-discovery'><p style={{marginBottom:11,color:'white'}}>Measurement Gap</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Data Governance</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Action Pipelines</p></Link>
          {/* <p style={{marginBottom:11,color:'white'}}>Bamboo Density Map</p>
           <p style={{marginBottom:11,color:'white'}}>Economic Potential Map</p>
           <p style={{marginBottom:11,color:'white'}}>Sustainable Extraction Map</p> */}
        </div>
        <div className="science" style={{ width: "16%" }}>
          <h6 style={{ color: "#c0cbc7" }}>Science</h6>
          {/* <p style={{marginBottom:11,color:'white'}}>Regeneration and Recruitment Trends</p> */}
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} ><p style={{marginBottom:11,color:'white'}}>Publications</p></Link>
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}> <p style={{marginBottom:11,color:'white'}}>Request Data</p></Link>
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Sustainability Impacts</p></Link>
          
         <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Gaia API</p></Link>
        </div>
        <div className="about" style={{ width: "22%", paddingLeft: 60 }}>
          <h6 style={{ color: "#c0cbc7" }}>About</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Team</p></Link>
          {/* <p style={{marginBottom:11,color:'white'}}>Resources</p> */}
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>nCount Intiative</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Opportunities</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='/about'><p style={{marginBottom:11,color:'white'}}>Contact</p></Link>
        </div>
        <div className="help" style={{ width: "20%" }}>
          <h6 style={{ color: "#c0cbc7" }}>Help</h6>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Getting started with nCount</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Frequently Asked Questions</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Data Sharing Policy</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}> <p style={{marginBottom:11,color:'white'}}>Privacy Policy</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}}><p style={{marginBottom:11,color:'white'}}>Terms of Use</p></Link>
          <Link style={{textDecoration:'none',color:'#c0cbc7',fontSize:13}} to='https://forum.ncount.in/' target="_blank"><p style={{marginBottom:11,color:'white'}}>Discussion Forum</p></Link>
          {/* <p>Contact</p> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            width: "22%",
            paddingRight: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ width: 90, marginRight: 10 }}
              className="openSourceIcon"
              src={sdgLogo}
            />
            <img
              style={{ width: 80 }}
              className="openSourceIcon"
              src={OpenSourceIcon}
            />
          </div>
          <div>
            <a href="https://www.instagram.com/ncount_iofe/" target="_blank">
              <img src={instaIcon} style={{ width: 25 }} />
            </a>
            &nbsp;
            <a href="https://www.youtube.com/@nCount-IOFE/" target="_blank">
              <img src={youtubeIcon} style={{ width: 32 }} />
            </a>
            &nbsp;
            <a
              href="https://github.com/Forest-Economy-Alliance"
              target="_blank"
            >
              <img src={githubIcon} style={{ width: 30 }} />
            </a>
            &nbsp;
            <a
             href="https://twitter.com/ncountforum" target="_blank"
            >
              <img src={twitterIcon} style={{ width: 30}} />
            </a>
            &nbsp;
            <a href="https://linkedin.com/in/ncount-platform-for-sustainability-action-856679303" target="_blank">
              <img src={linkendinIcon} style={{ width: 31 }} />
            </a>
          </div>
        </div>
        <center>
          <hr style={{ height: 1, color: "white" }} />
        </center>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ color: "#fff", fontSize: 14, fontWeight: 400 }}>
            2024 nCount. All rights reserved.
          </p>
          <img style={{ width: 30 }} src={footerLogo} />
        </div>
      </Row>
    </Container>
  );
}

export default Footer;
