import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import "../Login.css";
import {
    handleVerifyOtp,
  } from "../../../store/actions/authAction";

function AuthOtp({updateParentState,phoneNumber}) {
    const dispatch = useDispatch()
    const location  = useLocation();
    const timer = useRef();  
    const [otp, setOtp] = useState(null);
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    var registerUserInfo = useSelector((state) => state.registerUserInfo);
    console.log(registerUserInfo,"registeruser info")
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      const handleClick = () => {
        updateParentState({
            screenNumber:3
        });
      };

    const verifyOtp = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (otp === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            countryCode: "+91",
            phoneNumber: phoneNumber,
            otp: otp,
          };
          dispatch(
            handleVerifyOtp(data, (args) => {
              // setappInfo.loading(false);
              handleClick()
              console.log("ARGS->", args);
              setOtp(null);
              
            })
          );
        }
      };
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
         
        <Row style={{justifyContent:'center'}}>
            <Col md="12">
            <Form>
                <FormGroup>
                  <Input
                    className="inputBox"
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ marginTop: 35 }}
                    placeholder="Please Enter OTP"
                    autoComplete="off"
                  />
                </FormGroup>
                <center>

                <Button
                  className="submitButton"
                  style={{ marginTop: 32 }}
                  onClick={()=>verifyOtp()}
                >
                  {
                    appInfo.loading?"Loading..." : "Submit OTP"
                  }
                   
                </Button>

                {error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                    ) : null}
                </center>
              </Form>
    
            </Col>
        </Row>
    </Container>
  )
}


export default AuthOtp