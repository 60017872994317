import React,{useState,useEffect} from "react";
import aboutImage from "../../assets/Images/About.png";
import leafIcon from "../../assets/Icons/Leaf.png";
import profImage from "../../assets/team/Ashwini_Chatre.jpg";
import abihjeetImage from "../../assets/team/abhijeet.png";
import sanjoyImage from "../../assets/team/sanjoy.png";
import bhumikaImage from "../../assets/team/bhumika.png";
import siddharthImage from "../../assets/team/sky.png";
import niteshImage from "../../assets/team/nitesh.png";
import satyaImage from '../../assets/team/satya.png'
import prof from '../../assets/team/1.png'
import youImage from '../../assets/team/You.png'
import './About.css'
import {
  Container,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
function AboutComp1() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log (windowSize)
  return (
    <>
      <Container style={{ background: "#0a3b0a", color: "#fff" }}>
        <Row>
          <Col md="3" sm="6" xs="12">
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                  fontSize: 14,
                  color: "#b7b5b2",
                }}
              >
                Home
              </p>
            </Link>
            <h2 className="title1" style={{ marginBottom: 15, fontSize: 35 }}>About nCount</h2>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <Container style={{ marginTop: 25 }}>
        <Row>
          <Col md="7" sm="12" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <img src={aboutImage} style={{ width: "100%", height: "100%" }} />
          </Col>
          <Col
            md="5"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              background: "#e9ebea",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            sm="12"
          >
            <div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  style={windowSize.width <778?{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                    marginTop:15
                  }:{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                  }}
                ></div>
                <p style={{ color: "#0a3b0a", fontWeight: 500, fontSize: 13 }}>
                  Measure your floral and faunal resources{" "}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                  }}
                ></div>
                <p style={{ color: "#0a3b0a", fontWeight: 500, fontSize: 13 }}>
                  Discover actionable scientific insights
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                  }}
                ></div>
                <p style={{ color: "#0a3b0a", fontWeight: 500, fontSize: 13 }}>
                  Promote ethical and open science{" "}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                  }}
                ></div>
                <p style={{ color: "#0a3b0a", fontWeight: 500, fontSize: 13 }}>
                  Open eDNA repository for rapid biodiversity monitoring{" "}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 30,
                    background: "rgb(170, 141, 39)",
                    marginRight: 10,
                  }}
                ></div>
                <p style={{ color: "#0a3b0a", fontWeight: 500, fontSize: 13 }}>
                  Always Free and OpenSource
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        style={{
          marginTop: 25,
          paddingLeft: 15,
          paddingRight: 20,
          color: "#0a3b0a",
        }}
      >
        <p style={{ marginTop: 20, fontWeight: 500, fontSize: 15 }}>Your nCount journey … </p>
        <p style={{ marginTop: 40, fontSize: 14,textAlign:'justify' }}>
          nCount represents a cutting-edge approach to large scale prosperity
          and social well-being, through environmental monitoring and
          conservation. nCount is specifically designed to engage a diverse
          range of users, including forest-dwelling communities, citizen
          scientists, school and college students, nature enthusiasts, and
          researchers. Through an intuitive and user-friendly interface,
          participants can actively contribute to the scientific understanding
          of biodiversity by documenting plant and animal species in their local
          ecosystems. nCount harnesses the power of Artificial Intelligence (AI)
          and computer vision to assist in the identification and classification
          of species from images, ensuring accurate and efficient data
          processing.
        </p>
        <p style={{ marginTop: 30, fontSize: 14,textAlign:'justify' }}>
          Furthermore, the integration of 3D gaming technology and Augmented
          Reality (AR) enhances the user experience and educational aspects of
          the platform. School and college students, in particular, can immerse
          themselves in a virtual environment that replicates real-world
          ecosystems, allowing for interactive learning experiences. This
          gamified approach not only makes the process of data collection more
          engaging but also serves as an educational tool, fostering a deeper
          understanding of ecological concepts and the importance of
          biodiversity. Additionally, nCount extends its reach to empower
          forest-dwelling communities, enabling them to actively participate in
          forest inventory activities, contributing valuable local knowledge and
          strengthening their role in sustainable forest management. Overall,
          nCount represents a holistic and inclusive approach to biodiversity
          monitoring and environmental education, leveraging advanced
          technologies to bridge the gap between scientific research and
          community engagement.
        </p>
        <p style={{ marginTop: 30, fontSize: 14,textAlign:'justify' }}>
          In addition to its innovative features, our state-of-the-art
          technology platform excels in its capacity to collect environmental
          DNA (eDNA) data, further enhancing its capabilities for comprehensive
          biodiversity monitoring. By incorporating eDNA sampling techniques,
          the platform enables users to gather genetic material shed by
          organisms into the environment, offering a non-intrusive and highly
          sensitive method for species detection. Citizen scientists, school and
          college students, nature enthusiasts, and forest-dwelling communities
          can contribute to eDNA collection by simply collecting samples from
          water bodies, soil, or air. Advanced AI algorithms are then employed
          to analyze the eDNA data, providing a more nuanced understanding of
          the biodiversity present in a given ecosystem. This cutting-edge
          approach not only expands the scope of the platform's data collection
          capabilities but also allows for the detection of elusive or cryptic
          species that may be challenging to identify through traditional
          observation methods. In essence, the integration of eDNA technology
          enhances the platform's precision and depth, making it a formidable
          tool for monitoring and conserving biodiversity in a rapidly changing
          environment.
        </p>
        <p style={{ marginTop: 20, fontWeight: 500, fontSize: 15 }}>
          Welcome onboard !
        </p>
      </Container>
      <Container style={{ marginTop: 50, paddingLeft: 0,paddingRight:0, color: "#0a3b0a" }}>
        <h6 style={{ fontSize: 18, marginLeft:1}}>Living beings behind nCount</h6>
        <div style={{ marginTop: 20,marginBottom:20, background: "#e6e6db", paddingRight: 10,paddingLeft:10,paddingTop:20,borderRadius:3 }}>
          <Container>
            <Row style={{ justifyContent: "center" }}>
            {
                windowSize.width <778 ?
                null 
                :
                <Col md="5"></Col>

              }
           
            <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch',paddingRight:15}} sm="6" xs="6">
            <img
                  style={{
                    height: 150,
                    border:0,
                    width: 140,
                  }}
                  src={youImage}
                  />
                  <p className="title4" style={{marginBottom:0}}>
                  You 
                  </p>
                  {/* <p className="title">Idea.Philosophy</p> */}
            </Col>
            {
                windowSize.width <778 ?
                null 
                :
                <Col md="5"></Col>

              }
                  {
                windowSize.width <778 ?
                null 
                :
                <Col md="2"></Col>

              }
               
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
                <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={prof}
                  />
                  <p className="title" style={{marginBottom:0}}>
                  Ashwini Chhatre 
                  </p>
                  <p className="title">Idea.Philosophy</p>
              </Col>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={satyaImage}
                  />
                 <p className="title" style={{marginBottom:0}}>
                  Satya Prasanna 
                  </p>
                  <p className="title" style={{marginBottom:0}}>Idea.Philosophy</p>
              </Col>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={abihjeetImage}
                  />
                 <p className="title" style={{marginBottom:0}}>
                  Abhijeet Parmar
                  </p>
                  <p className="title" style={{marginBottom:0}}>Concept.Design</p>
              </Col>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={sanjoyImage}
                  />
                  <p className="title2" style={{marginBottom:0}}>
                  Sanjoy Mondal 
                  </p>
                  <p className="title2">Leader.Field</p>
              </Col>
              {
                windowSize.width <778 ?
                null 
                :
                <>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}}  sm="0" xs="0"></Col>
                </>

              }
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}}  sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={bhumikaImage}
                  />
                  <p className="title2" style={{marginBottom:0}}>
                  Bhumika Morey 
                  </p>
                  <p className="title2">Spatial.Information</p>
              </Col>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={siddharthImage}
                  />
                  <p className="title2" style={{marginBottom:0}}>
                  Siddharth Kumar Yadav 
                  </p>
                  <p className="title2">DevOps.Framework</p>
              </Col>
              <Col md="2" style={{display:'flex',flexDirection:'column',alignItems:'stretch'}} sm="6" xs="6" style={windowSize.width <778  ?null :{marginTop:25}}>
              <img
                  style={{
                    height: 150,
                   border:0,
                    width: 140,
                  }}
                  src={niteshImage}
                  />
                  <p className="title2" style={{marginBottom:0}}>
                  Nitesh Saini 
                  </p>
                  <p className="title2">DevOps.Framework</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AboutComp1;
