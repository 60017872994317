import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Login.css";
import { handleVerifyOtp } from "../../../store/actions/authAction";
function ForgetPasswordOtp({updateParentState,phoneNumber}) {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();
    const timer = useRef();  
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      const handleClick = () => {
        updateParentState(3);
      };
      const verifyOtp = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (otp === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            countryCode: "+91",
            phoneNumber: phoneNumber,
            otp: otp,
          };
          dispatch(
            handleVerifyOtp(data, (args) => {
              console.log("Verifying otp....")
              handleClick()
              console.log("ARGS->", args);
              setOtp(null);
              
            //   mobile-password
              // setRegisterationStarted(true)
            })
          );
        }
      };
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
         
    <Row>
        <Col md="12">
        <Form>
            <FormGroup>
              <Input
                className="inputBox"
                onChange={(e) => setOtp(e.target.value)}
                style={{ marginTop: 35 }}
                placeholder="Please Enter Otp"
                autoComplete="off"
                value={otp}
              />
            </FormGroup>
            <center>

            <Button
              className="submitButton"
              style={{ marginTop: 32 }}
              onClick={()=>verifyOtp()}
            >
              {
                appInfo.loading?"Loading..." : "Verify OTP"
              }
               
            </Button>


            {error !== "" ? (
                <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                ) : null}
            </center>
            <Button
              className="submitButton"
              style={{ marginTop: 32,color:'#76561c',background:'white',fontSize:13 }}
              onClick={()=>updateParentState(0)}
            >
              Signin Now
            </Button>
          </Form>
        </Col>
    </Row>
</Container>
  )
}

export default ForgetPasswordOtp