import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import "../Login.css";
import { Link } from "react-router-dom";
import { handleVerifyOtp } from "../../../store/actions/authAction";
function ForgetPasswordOtp() {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();
    const timer = useRef();  
    const location  = useLocation();
    useEffect(()=>{
        if(!location?.state?.phoneNumber){
           navigate("/mobile-forget-password")
        }
       },[])
       const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
      
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
       useEffect(()=>{
        if(windowSize.width>=778){
            navigate("/auth");
        }

      },[windowSize.width])
    const phoneNumber  = location?.state?.phoneNumber;
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      const handleClick=()=>{
        navigate("/mobile-forget-password-update",{ state: { phoneNumber: phoneNumber } }) 
      }
     
      const verifyOtp = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (otp === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            countryCode: "+91",
            phoneNumber: phoneNumber,
            otp: otp,
          };
          dispatch(
            handleVerifyOtp(data, (args) => {
              console.log("Verifying otp....")
               
              console.log("ARGS->", args);
              setOtp(null);
              navigate("/mobile-forget-password-update",{ state: { phoneNumber: phoneNumber } }) 
            //   mobile-password
              // setRegisterationStarted(true)
            })
          );
        }
      };
  return (
    <Container className="bgImg">
          <Row>
        <center>
            <Link to="/">
              <img style={{ width: 250,marginBottom:20,marginTop:40 }} src={whiteLogo} />
            </Link>
          </center>
        </Row>   
    <Row  style={{
             marginTop: 10,
             height:380,
             background: "rgba(216,216,216,0.48)",
             boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
             borderRadius: 5,
             backdropFilter: "blur(5px)",
             padding: 20,
             margin:5,
        }}>
        <Col md="10">
        <Form>
            <FormGroup>
              <Input
                className="inputBox"
                onChange={(e) => setOtp(e.target.value)}
                style={{ marginTop: 35 }}
                placeholder="Please Enter Otp"
                autoComplete="off"
                value={otp}
              />
            </FormGroup>
            <center>

            <Button
              className="submitButton"
              style={{ marginTop: 32 }}
              onClick={()=>verifyOtp()}
            >
              {
                appInfo.loading?"Loading..." : "Verify OTP"
              }
               
            </Button>

            {error !== "" ? (
                <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                ) : null}
            </center>
            <center>

            <Button
              className="submitButton"
              style={{ marginTop: 32,color:'#76561c',background:'white',fontSize:13 }}
              onClick={()=>(navigate("/mobile-auth"))}
              >
              Signin Now
            </Button>
              </center>
          </Form>
        </Col>
    </Row>
</Container>
  )
}

export default ForgetPasswordOtp