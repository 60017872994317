import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import challenge_icon from "../../../assets/Icons/challenge_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Login.css";
import { handleUpdatePassword } from "../../../store/actions/authAction";
function ForgetPasswordUpdate({updateParentState,phoneNumber}) {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isRegisterationCompleted,setRegisterationCompleted] = useState(false)
    const [error,setError] = useState("")
    var appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();
    const timer = useRef();  
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      const handleClick = () => {
        updateParentState(0);
      };
      const updatePassword = () => {
        // setappInfo.loading(true);

        console.log("function login called");
        if (password === null || confirmPassword === null) {
            setError("*Please Fill All the Fields!!");
            timer.current = setTimeout(resetError, 1500);
          // setappInfo.loading(false); 
        } else if (password !== confirmPassword) {
          setError("*Password didn't match!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            
            phoneNumber: phoneNumber,
            password: password,
          };
          dispatch(
            handleUpdatePassword(data, (args) => {
              console.log("ARGS->", args);
              setRegisterationCompleted(true)
            })
          );
        }
    }
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
         
    <Row>
        <Col md="12">
        {
    isRegisterationCompleted ? (
              <center>
                <div>
                  <img style={{ width: 130 }} src={challenge_icon} />
                  <p
                    style={{
                      color: "#094709",
                      fontSize: 18,
                      marginTop: 10,
                      marginLeft: 15,
                    }}
                  >
                    Your password is updated!!
                  </p>
                  <Button
                    className="submitButton"
                    style={{ marginTop: 10 }}
                    onClick={()=>handleClick()}
                  >
                    Sign In Now
                  </Button>
                </div>
              </center>
            ) :
        <Form>
            <FormGroup>
              <Input
                className="inputBox"
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: 35 }}
                placeholder="Please Enter Password"
                autoComplete="off"
                value={password}
              />
               <Input
                className="inputBox"
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ marginTop: 35 }}
                placeholder="Please Confirm Password"
                autoComplete="off"
                type="password"
                value={confirmPassword}
              />
            </FormGroup>
            <center>

            <Button
              className="submitButton"
              style={{ marginTop: 32 }}
              onClick={()=>updatePassword()}
            >
              {
                appInfo.loading?"Loading..." : "Update Password"
              }
               
            </Button>

            {error !== "" ? (
                <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                ) : null}
            </center>
            <Button
              className="submitButton"
              style={{ marginTop: 32,color:'#76561c',background:'white',fontSize:13 }}
              onClick={()=>updateParentState(0)}
            >
              Signin Now
            </Button>
          </Form>
}
        </Col>
    </Row>
</Container>
  )
}

export default ForgetPasswordUpdate