
import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link } from "react-router-dom";
import {
    handleRegisterUser,
  } from "../../../store/actions/authAction";
  import { useNavigate } from "react-router-dom";
import "../Login.css";

function RegisterMobile() {
    const timer = useRef(); 
    const [error, setError] = useState("");
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const dispatch = useDispatch()
    var appInfo = useSelector((state) => state.appInfo);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
    const navigate= useNavigate()
    useEffect(()=>{
        if (appInfo.error!==null) {
          const timeoutId = setTimeout(() => {
            dispatch({type:'REMOVE_ERROR'})
          }, 1000);
    
          return () => clearTimeout(timeoutId);
        }
      },[appInfo.error])
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
      
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(()=>{
        if(windowSize.width>=778){
            navigate("/auth");
        }

      },[windowSize.width])

    const handleRegister = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (firstName === null || phoneNumber === null) {
          // setappInfo.loading(false)
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else if (phoneNumber.length != 10) {
          // setappInfo.loading(false);
          setError("*Phone Number should contains only 10 digit ");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let user = {
            firstName: firstName,
            phoneNumber: phoneNumber,
            lastName: lastName,
          };
          dispatch(
            handleRegisterUser(user, (args) => {
              console.log("ARGS->", args);
              user = {
                phoneNumber:phoneNumber,
                firstName:firstName,
                lastName:lastName
              }
              dispatch({type:'SET_REGISTER_USER_INFO',payload:user})
              navigate("/mobile-otp", { state: { user: user } });
            })
          );
        }
      };
    
  return (
    <Container className="bgImg">
    <Row>
    <center>
        <Link to="/">
          <img style={{ width: 250,marginBottom:20,marginTop:40 }} src={whiteLogo} />
        </Link>
      </center>
    </Row>
    <Row style={{
         marginTop: 10,
         height: 380,
         background: "rgba(216,216,216,0.48)",
         boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
         borderRadius: 5,
         backdropFilter: "blur(5px)",
         padding: 20,
         margin:5
    }}>
        <Col md="10">
        <Form>
                <FormGroup>
                  <Input
                    className="inputBox"
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    autoComplete="off"
                  />
                  <Input
                    onChange={(e) => setFirstName(e.target.value)}
                    className="inputBox"
                    style={{ marginTop: 35 }}
                    placeholder="First Name"
                    autoComplete="off"
                  />
                  <Input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="inputBox"
                    style={{ marginTop: 35 }}
                    placeholder="Mobile Number"
                    autoComplete="off"
                  />
                </FormGroup>
                {/* <Link to="/otp"> */}
                <center>
                <Button
                  className="submitButton"
                  onClick={() => handleRegister()}
                >
                  {" "}
                  {appInfo.loading? "Loading...":"Register"}
                </Button>
                <p onClick={()=>navigate("/mobile-auth")} style={{marginTop:20,color:'#094709'}}>Already have an account. <span style={{color:'#094709'}}>Signin Now</span></p>

                {error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                    ) : null}

                {appInfo.error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>
                    {appInfo.error}
                  </p>
                ) : null}
                </center>

                {/* </Link> */}
                
              </Form>
        </Col>
        <center>
                  <p style={{width:'100%',marginTop:50, color:'#fff',fontWeight:400}}>2024 nCount. All rights reserved.</p>
                  </center>
    </Row>
</Container>
  )
}

export default RegisterMobile