import React,{useState} from 'react'
import test_form_icon from '../../../assets/Icons/test-form-icon.png'
import odisha_form_icon from '../../../assets/Icons/Odisha-form-icon.png'
import him_form_icon from '../../../assets/Icons/Him-form-icon.png';
import carbon_form_icon from '../../../assets/Icons/carbon-form-icon.png';
import boundary_form_icon from '../../../assets/Icons/Boundary_form_icon.png'
import gadchirowliForestMapping from '../../../assets/Icons/Gadchirowli.png';
import boundaryMapping from '../../../assets/Icons/Boundary.png';
import {Container,Col,Row,Button} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function StartCollection() {
    const user = useSelector((state) => state.userInfo);
    const appInfo = useSelector(state=>state.appInfo);
    const [loading, setLoading] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
  
    const BASE_URL = "https://htj5xy3nrv.us-east-1.awsapprunner.com";
     console.log("user",user)
    const dispatch = useDispatch();
    const allForms = [
        {
          id: 1,
          name:"Test Form",
          name2:"Tree Species Distribution",
          formId: "tree_test_24",
          roleId: 2,
          projectId: 1,
          icon:test_form_icon
        },
        {
            id: 2,
            name:"Tree Species Distribution",
            name2:"Himachal Pradesh",
            formId: "himachal_resource_map",
            roleId: 2,
            projectId: 1,
            icon: him_form_icon
          },
        {
          id: 3,
          name:"Tree Species Distribution",
          name2: "Odisha",
          formId: "tree_test_b_odiya",
          roleId: 2,
          projectId: 1,
          icon: odisha_form_icon
        },
          {
            id: 4,
            name:"Forest Carbon",
            name2:"Above Ground Biomass",
            formId: "forest_sustainability_mapping",
            roleId: 2,
            projectId: 1,
            icon: carbon_form_icon
          },
          {
            id: 5,
            name:"Boundary Mapping",
            name2:"Create Region Maps",
            formId: "boundary_all",
            roleId: 2,
            projectId: 1,
            icon: boundary_form_icon
          },
          {
            id: 6,
            name:"Gadchiroli",
            name2:"Forest Resource Mapping",
            formId: "gadchiroli_resource_map",
            roleId: 2,
            projectId: 1,
            icon: gadchirowliForestMapping
          },
          {
            id: 7,
            name:"Gadchiroli",
            name2:"Boundary Mapping",
            formId: "gadchiroli_bound",
            roleId: 2,
            projectId: 1,
            icon: boundaryMapping
          },
          {
            id: 8,
            name:"Sonbhadra",
            name2:"Forest Resource Mapping",
            formId: "sonbhadra_resource_map",
            roleId: 2,
            projectId: 1,
            icon: odisha_form_icon
          },
          {
            id: 9,
            name:"Sonbhadra",
            name2:"Boundary Mapping",
            formId: "sonbhadra_bound_id",
            roleId: 2,
            projectId: 1,
            icon: boundary_form_icon
          },
      ];
    

  const  handleClickedAccess = (selectedOption) => {
    console.log(selectedOption)
     setSelectedForm(selectedOption)
     handleRequestAccess(selectedOption)
   };

//   Request Access
const handleRequestAccess = async (Form) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/nCount/odk/user-form-access`, {
        projectId: Form.projectId,
        formId: Form.formId,
        roleId: Form.roleId,
        userId: user.odkUserId,
      })
      .then(function (response) {
        setLoading(false);
        alert("You have the form access now.");
        // dispatch({ type: "UPDATE_ODK_FORM_ACCESS", payload: response.data });
        setSelectedForm(null);
      })
      .catch(function (error) {
        setLoading(false);
        alert("You already have this form access.");
        setSelectedForm(null);
        setLoading(false);
        console.log(error);
      });
  };


  return (
    <>
    <p style={{color:'#124912'}}>
        Your engagement in data collection for natural, physical, and anthropogenic systems is vital. It expands data coverage, especially in remote areas, fostering a deeper understanding of system dynamics. This involvement nurtures a sense of responsibility and empowers communities to address environmental issues collaboratively. Your contributions will bolster scientific understanding and promote effective environmental stewardship globally.
    </p>
    <p style={{fontWeight:500,color:'#124912'}}>
        Please select any campaign below to get started!
    </p>
     
        
<Container>
    <Row style={{alignItems:'flex-end'}}>

         
     {
         allForms.map((form)=>{
             return(
                 <>
                <Col md="4" sm="6" xs="6">
             <center>
             <img src={form.icon} style={{height:50,marginTop:30}} />
             <div>
             <p style={{color:'#124912',fontSize:16,fontWeight:400,marginBottom:2}}>{form.name}</p>
             <p style={{color:'#124912',fontSize:16,fontWeight:400,marginTop:0}}>{form.name2}</p>

             </div>
             <Button onClick={()=>handleClickedAccess(form)} style={{fontSize:14,marginTop:0,borderRadius:4,background:'#c0810f'}} size="sm" className="submitButton">
                  {
                    (selectedForm?.id==form.id && loading) ? 'Accessing..':'Access'
                  }
                </Button>
                </center>
    </Col>
                </>
            )
        })
    }
</Row>
</Container>
 
    </>
  )
}

export default StartCollection