import React from 'react'
import mainBg from '../../assets/science/p1.png'
import visualEngine from '../../assets/science/datavisual.png';
import uavEngine from '../../assets/science/drone.png';
import eDnaImage from '../../assets/science/eDNA.jpg';
import augumentedEngine from '../../assets/science/3D.jpg';
import openData from '../../assets/science/opendata.jpg';
import ionImage from '../../assets/science/ion.png';
import {
    Container,
    Input,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col,
  } from "reactstrap";
  import './Science.css'
  const containerdata = [{
    img:visualEngine,
    title:'Visual Engine',
    link:"http://geoi.ncount.in/",
    desc:"Dive into high-resolution maps, uncover hidden hotspots, and chart the distribution of diverse species. Contribute your observations to unlock comparative insights - all powered by your active participation."
  },
  {
    img:uavEngine,
    title:'UAV Engine',
    desc:"We're developing an open-source, automated workflow that uses drone imagery to produce highly accurate, large-scale maps of shrub, herb, and forest distribution at exceptionally high resolution."
  },
  {
    img:eDnaImage,
    title:'eDNA Analytical Engine',
    desc:"Simplified eDNA collection and analysis for everyone. Discover new species, monitor vulnerable populations, and contribute to groundbreaking conservation efforts - all without physically disturbing the environment"
  },
  {
    img:augumentedEngine,
    title:'Agumented Reality Engine',
    desc:"Uncover the hidden world beneath the forest canopy with AR. Use nCount platform and app to visualize data layers on your surroundings, revealing insect populations, fungal networks, and even hidden animal tracks. Become a citizen scientist and unlock the secrets of nature"
  },
  {
    img:openData,
    title:'Open Data and Tools',
    desc:"Tinker with open-source electronics, digital tools and resources that reveal biodiversity secrets. We believe in open access and ethical sharing of data and resources. Join our dynamic community, share your findings, and contribute to a healthier planet."
  },
  {
    img:ionImage,
    title:'I wish to be...IoG',
    desc:"In the not-too-distant future, nCount aspires to evolve into the Internet of Greens (loG), a network of interconnected components seamlessly interacting and generating actionable insights for the health and prosperity of our planet."
  },
]
function ScienceComp() {
  return (
    <Container className='HeroContainer'>
        <div 
        style={{
          display: 'flex',
          position: 'relative',
        height: '80vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundImage: 'url(/p1.png)'
        }}
        >
           <div className='MainHeading'>
            <h2 className='MainHeadingTitle'>nCount Science</h2>
            <h6 className='MainHeadingSubtitle'>Research | Knowledge | Transformation</h6>  
           </div>
        </div>
        <Row style={{marginTop:20}}>
          {
            containerdata.map((data,key)=>
            {
              console.log(key,"keyy")
              if(key===2 || key===5){
                return(
                  <Col md="4" className='mostRightCont'>
          
                  <a href={data.link} target='_blank'  style={{textDecoration:'none',cursor:'pointer'}}>
                 <img className='placeHolderImg' src={data.img} alt={data.title}/>
                  <h5 className='placeHolderImgTitle'>{data.title}</h5>
                  <p className='placeHolderImgDesc'>{data.desc}</p>
                  </a>
                 </Col>
                )
              }
              else{
              return(
         <Col md="4" className='mostLeftCont'>
          
          <a href={data.link} target='_blank'  style={{textDecoration:'none',cursor:'pointer'}}>
         <img className='placeHolderImg' src={data.img} alt={data.title}/>
          <h5 className='placeHolderImgTitle'>{data.title}</h5>
          <p className='placeHolderImgDesc'>{data.desc}</p>
          </a>
         </Col>

              )
              }
            })
          }
        </Row>
    </Container>
  )
}

export default ScienceComp