import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link } from "react-router-dom";
import {
    handleRegisterUser,
  } from "../../../store/actions/authAction";
  import { useNavigate } from "react-router-dom";
import "../Login.css";


function AuthRegister({updateParentState}) {
    const timer = useRef(); 
    const [error, setError] = useState("");
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const dispatch = useDispatch()
    var appInfo = useSelector((state) => state.appInfo);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
    const navigate= useNavigate()
    useEffect(()=>{
        if (appInfo.error!==null) {
          const timeoutId = setTimeout(() => {
            dispatch({type:'REMOVE_ERROR'})
          }, 1000);
    
          return () => clearTimeout(timeoutId);
        }
      },[appInfo.error])
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
      
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(()=>{
        if(windowSize.width>=778){
            navigate("/auth");
        }

      },[windowSize.width])

      const handleClick = () => {
        // Update state value in parent component
        updateParentState({
            screenNumber:2,
            phoneNumber:phoneNumber,
            firstName:firstName,
            lastName:lastName
        });
      };

    const handleRegister = () => {
        console.log("function login called");
        if (firstName === null || phoneNumber === null) {
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else if (phoneNumber.length != 10) {
          setError("*Mobile Number should contains only 10 digit ");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let user = {
            firstName: firstName,
            phoneNumber: phoneNumber,
            lastName: lastName,
          };
          dispatch(
            handleRegisterUser(user, (args) => {
              console.log("ARGS->", args);
              user = {
                phoneNumber:phoneNumber,
                firstName:firstName,
                lastName:lastName
              }
              dispatch({type:'SET_REGISTER_USER_INFO',payload:user})
              handleClick()
            })
          );
        }
      };
    
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
  
    <Row>
        <Col md="12">
        <Form>
                <FormGroup>
                  <Input
                    className="inputBox"
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    autoComplete="off"
                  />
                  <Input
                    onChange={(e) => setFirstName(e.target.value)}
                    className="inputBox"
                    style={{ marginTop: 35 }}
                    placeholder="First Name"
                    autoComplete="off"
                  />
                  <Input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="inputBox"
                    style={{ marginTop: 35 }}
                    placeholder="Mobile Number"
                    autoComplete="off"
                  />
                </FormGroup>
                {/* <Link to="/otp"> */}
                <center>
                <Button
                  className="submitButton"
                  onClick={handleRegister}
                >
                  {" "}
                  {appInfo.loading? "Loading...":"Register"}
                </Button>
                 

                {error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                    ) : null}

                {appInfo.error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>
                    {appInfo.error}
                  </p>
                ) : null}
                </center>

                {/* </Link> */}
                
              </Form>
        </Col>
       
    </Row>
</Container>
  )
}

export default AuthRegister