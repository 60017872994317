import React from 'react'
import {
    Container,
    Input,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col,
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import duImage from '../../assets/Images/DU.png'
  import hpImage from '../../assets/Images/HP.png'
  import './Engagements.css'
function EngagementsComp() {
  return (
    <div style={{marginBottom:50}}>
      <Container style={{ background: "#0a3b0a", color: "#fff" }}>
        <Row>
          <Col md="3" sm="6" xs="12">
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                  fontSize: 14,
                  color: "#b7b5b2",
                }}
              >
                Home
              </p>
            </Link>
            <h2 className='title1' style={{ marginBottom: 15, fontSize: 35 }}>Engagements</h2>
          </Col>
          <Col></Col>
        </Row>
      </Container>   
      <Container>
        <center>
            <h6 style={{marginTop:25,marginBottom:25,fontSize:18,color:'#054805'}}>Our Partners in the Journey</h6>
        </center>
            <hr style={{height:1.5}}/>
            <p className='caption1'>
            nCount welcomes and actively promotes collaboration through a comprehensive initiative that fosters partnerships with communities, corporations, research 
            institutions, conservation organizations, and governmental bodies. By encouraging data sharing and joint research projects, the platform seeks to create a
             unified and robust knowledge base for forest resource inventory and biodiversity monitoring. Collaborative efforts extend to the development of new features
              and technological advancements, leveraging the collective expertise of diverse stakeholders. nCount emphasizes community engagement, ensuring that local knowledge
               and perspectives are integrated into research activities. Through these collaborations, the platform aims to establish a network of stakeholders dedicated to the common 
               goal of measuring, understanding, monitoring, and preserving the intricate ecosystems that contribute to global biodiversity. 
            </p>
      </Container>
      <Container style={{paddingLeft:50,marginTop:35}}>
        <Row style={{alignItems:'center'}}>
            <Col md="2">
                <img className='img1' src={hpImage} />
            </Col>
            <Col md="10">
                <p style={{fontSize:14,lineHeight:'1.4rem',color:'#054805',textAlign:'justify'}}>
            The adoption of nCount by the Himachal Pradesh Forest Department marks a significant initiative aimed at conducting a comprehensive forest inventory of herbs and medicinal plant 
            species in the state of Himachal Pradesh. The initiative is expected to enhance the scientific understanding of the diverse flora in Himachal Pradesh,particularly focusing on the herbs and medicinal plants. This forest inventory effort holds the promise of not only bolstering the scientific knowledge base but also contributing to the development of policies that balance ecological preservation with the socio-economic needs of local communities dependent on these valuable plant resources. Ultimately, the inventory outcome is poised to make a
             positive impact on biodiversity conservation and the sustainable utilization of medicinal plants in Himachal Pradesh, fostering a harmonious coexistence between nature and human well-being.
                </p>
            </Col>
        </Row>
      </Container>
      <Container style={{paddingLeft:50,marginTop:35}}>
        <Row style={{alignItems:'center'}}>
            <Col md="2">
                <img className='img2' src={duImage} />
            </Col>
            <Col md="10">
                <p style={{fontSize:14,lineHeight:'1.4rem',color:'#054805',textAlign:'justify'}}>
                The University of Delhi has embraced cutting-edge technology in biodiversity research through the use of the nCount platform. nCount facilitates the efficient collection and analysis of environmental DNA (eDNA) data, 
                enabling the university to conduct rapid and precise biodiversity monitoring. With the nCount platform, can swiftly identify and quantify various species present in an ecosystem by analyzing genetic material shed into the environment. 
                This innovative approach not only accelerates the pace of biodiversity assessments but also provides a non-invasive 
                means to monitor ecological changes. The University of Delhi's adoption of the nCount platform exemplifies a commitment to leveraging technology for enhancing biodiversity research and conservation efforts.
             </p>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EngagementsComp