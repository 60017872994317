import React, { useState, useRef, useEffect } from "react";
import AnimatedButton from "./AnimatedButton";
import bgImage from "../../assets/Images/signup.png";
import whiteLogo from "../../assets/logos/White on Transparent.png";
import { Link, useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import spinner from '../../assets/Icons/Loader.gif'
import AuthLogin from "./Webcomponents/AuthLogin";
import AuthRegister from "./Webcomponents/AuthRegister";
import AuthOtp from "./Webcomponents/AuthOtp";
import AuthPassword from "./Webcomponents/AuthPassword";
import ForgetPassword from "./Webcomponents/ForgetPassword";
import ForgetPasswordOtp from "./Webcomponents/ForgetPasswordOtp";
import ForgetPasswordUpdate from "./Webcomponents/ForgetPasswordUpdate";

function Login() {
  const timer = useRef();
  const [loader,setLoader] = useState(true); 
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isRegisterationStarted, setRegisterationStarted] = useState(true);
  const [otpScreenVisible, setOtpScreenVisible] = useState(false);
  const [passwordScreenVisible, setPasswordScreenVisible] = useState(false);
  const [password, setPassword] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [otp, setOtp] = useState(null);
  const [registerationpassword, setRegisterationPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isRegisterationCompleted, setRegisterationCompleted] = useState(false);
//  Login ---0 
//  Registeration Start --1 --toggle true
//  Registeration Otp --2
//  Registeration Password --3
// Forget Password ---1 --toggle false
//  Forget Password Otp --2
//  Forget Password Password --3
   const [loginScreenNumberActive,setLoginScreenNumberActive] = useState(0)
   const [registerScreenNumberActive,setRegisterScreenNumberActive] = useState(1)
  var appInfo = useSelector((state) => state.appInfo);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  console.log("error---------------->>>>>>>>>>", error);
  const controls = useAnimation();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    

    window.addEventListener('resize', handleResize);
  })
  useEffect(()=>{
    if (appInfo.error!==null) {
      const timeoutId = setTimeout(() => {
        dispatch({type:'REMOVE_ERROR'})
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  },[appInfo.error])
  
  const resetError = () => {
    setError("");
    clearTimeout(timer);
  };

  useEffect(()=>{
    if(windowSize.width<778){
        navigate("/mobile-auth");
    }

  },[windowSize.width])

 
  const handleToggle = () => {
    setToggle(!toggle);
     dispatch({type:'REMOVE_ERROR'})
    // Add animations
    setLoginScreenNumberActive(0)
    controls.start({
      x: toggle ? 0 : "50%",
    });
  };
  const imageLoad = () =>{
    setLoader(false)
  }
  console.log(registerScreenNumberActive,"loading......")
  const updateRegisterationStateValue = (newValue) => {
    console.log(newValue)
    setRegisterScreenNumberActive(newValue.screenNumber);
    setPhoneNumber(newValue.phoneNumber)
    setFirstName(newValue.firstName)
    setLastName(newValue.lastName)
  };
  const  updateRegisterationOtpState = (newValue) =>{
    console.log(newValue)
     setRegisterScreenNumberActive(newValue.screenNumber)
  }
  const updatePostRegisterationState = (newValue) =>{
    setRegisterScreenNumberActive(newValue)
   handleToggle()
  }
  let user={
    phoneNumber:phoneNumber,
    firstName:firstName,
    lastName:lastName
  }
  const updateLoginState=(newValue) =>{
    setLoginScreenNumberActive(newValue)
  }
  const updateLoginFogetPasswordState = (newValue) =>{
    console.log(newValue)
    setLoginScreenNumberActive(newValue.screenNumber);
    setPhoneNumber(newValue.phoneNumber)
  }
  console.log("login state",loginScreenNumberActive)
  return (
   
  
      
    <>
      {
        loader?
        <p style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}><img src={spinner}/></p>
        :null
      }
    {
      windowSize.width<778 ?
     <div style={{background:'#125912',width:'100%'}}>

     </div>
      :null
    }
      <div style={{ paddingRight: 0, paddingLeft: 0 }}>
        <img
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          src={bgImage}
          onLoad={imageLoad}
        />
      </div>
      <div style={loader?{display:'none'}:{ position: "absolute", left: '50%',top:'50%',transform:'translate(-50%,-50%)' }}>
        <div>
          <center>
            <Link to="/">
              <img style={{ width: 250,marginBottom:15 }} src={whiteLogo} />
            </Link>
          </center>
        </div>
        <center>
          <div
            style={windowSize.width <778 ? 
              {
                // width: 600,
                marginTop: 10,
                height: 400,
                background: "rgba(216,216,216,0.48)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: 5,
                backdropFilter: "blur(5px)",
                padding: 30,
                paddingLeft: 50,
                paddingRight: 50,
              }
              :{
              width: 600,
              marginTop: 0,
              height: 400,
              background: "rgba(216,216,216,0.48)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              borderRadius: 5,
              backdropFilter: "blur(5px)",
              padding: 30,
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            <div className="app">
              <div className="toggle-container" onClick={handleToggle}>
      <motion.div
        className="toggle-background"
        initial={false}
        animate={controls}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      />
      <div className="toggle-text-container">
        <div onClick={()=>setLoginScreenNumberActive(0)} className={`toggle-text login ${toggle ? "active" : ""}`}>
          Sign In
        </div>
        <div className={`toggle-text signup ${!toggle ? "active" : ""}`}>
          Register
        </div>
      </div>
    </div>
            </div>

            {/* For Intial Registeration Screen */}
            {toggle &&
             registerScreenNumberActive === 1? (
             <AuthRegister updateParentState={updateRegisterationStateValue}/>
            ) : null}

            {/* For Otp Screen */}
            {toggle && registerScreenNumberActive==2
             ? (
               <AuthOtp updateParentState={updateRegisterationOtpState} phoneNumber={phoneNumber}/>
            ) : null}
            {/* For password */}
            {toggle && registerScreenNumberActive==3? (
               <AuthPassword updateParentState ={updatePostRegisterationState} user={user}/>
            ) : null}
            {!toggle && loginScreenNumberActive==0 ? (
              <AuthLogin updateParentState ={updateLoginState}/>
            ) : null}
             {!toggle && loginScreenNumberActive==1 ? (
              <ForgetPassword updateParentState ={updateLoginFogetPasswordState}/>
            ) : null}
              {!toggle && loginScreenNumberActive==2 ? (
              <ForgetPasswordOtp phoneNumber={phoneNumber} updateParentState ={updateLoginState}/>
            ) : null}
            {!toggle &&  loginScreenNumberActive==3 ? (
              <ForgetPasswordUpdate updateParentState={updateLoginState} phoneNumber={phoneNumber}/>
            ) : null}
            
          </div>
          <p style={{color:'white',marginTop:20}}>2024 nCount. All rights reserved.</p>
        </center>
      </div>
               
    </>
                
  );
}

export default Login;
