import React from 'react'
import mainImage from '../../assets/Images/science.png'
import './HeroSection4.css'
import {
    Container,Col,Row,Button
} from 'reactstrap'
function HeroSection4() {
  return (
    <Container style={{marginTop:40}}>
        <Row>
            <Col md="8" style={{paddingLeft:0,paddingRight:0}}>
                <img className='mainImage' src={mainImage} />
            </Col>
            <Col md="4" className='HeroSection4Right'>
          
               

                <center>
                <h5 className='heroSection4Text'>
                    Advancing Science through Play
                </h5>
                <p style={{color:'white',paddingTop:10,paddingLeft:10,paddingRight:10,fontSize:14,fontWeight:300}}>
                    Your records will contribute to sustainable forest management decisions,
                    climate policy scintific discoveries and peer-reviewed publications, thousand
                    of student projects, and help inform biodiversity research worldwide.
                </p>
                <Button size='sm' style={{background:'rgb(161 130 18)',border:'none',borderRadius:3}}>Learn More</Button>
                    </center>
                <br></br>
        
              
            </Col>
        </Row>
    </Container>
  )
}

export default HeroSection4