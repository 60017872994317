import React from 'react'
import Header from '../Header/Header'
import NeedDiscoveryComp from './NeedDiscoveryComp'
import Footer from '../Footer/Footer'

function NeedDiscovery() {
  return (
    <>
    <Header/>
    <NeedDiscoveryComp/>
    <Footer/>
    </>
  )
}

export default NeedDiscovery