import React, { useState, useRef, useEffect } from "react";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link, useLocation } from "react-router-dom";
import challenge_icon from "../../../assets/Icons/challenge_icon.png";
import {
  handleCompleteRegisteration,
} from "../../../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Container, Input, Form, FormGroup, Label, Button,Row,Col } from "reactstrap";
import "../Login.css";
import { useNavigate } from "react-router-dom";
function PasswordMob() {
    const location = useLocation();
    const user  = location?.state?.user
    const [isRegisterationCompleted,setRegisterationCompleted] = useState(false)
    const [registerationpassword, setRegisterationPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();
    const timer = useRef();  
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
    useEffect(()=>{
     if(!location?.state?.user){
        dispatch({type:"REMOVE_REGISTER_USER_INFO"})
        navigate("/mobile-register")
     }
    },[])
    const completeRegisteration = () => {
        // setappInfo.loading(true);

        console.log("function login called");
        if (registerationpassword === null || confirmPassword === null) {
            setError("*Please Fill All the Fields!!");
            timer.current = setTimeout(resetError, 1500);
          // setappInfo.loading(false); 
        } else if (registerationpassword !== confirmPassword) {
          // setappInfo.loading(false); 
          setError("*Password didn't match!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            firstName: user.firstName,
            phoneNumber: user.phoneNumber,
            lastName: user.lastName,
            password: registerationpassword,
          };
          dispatch(
            handleCompleteRegisteration(data, (args) => {
              console.log("ARGS->", args);
              // dispatch({type:"REMOVE_REGISTER_USER_INFO"})
              setRegisterationCompleted(true)
            })
          );
        }
    }
    const authPage = () =>{
      dispatch({type:"REMOVE_REGISTER_USER_INFO"})
      navigate("/mobile-auth")
    }
  return (
<Container className="bgImg">
        <Row>
        <center>
            <Link to="/">
              <img style={{ width: 250,marginBottom:20,marginTop:40 }} src={whiteLogo} />
            </Link>
          </center>
        </Row>
        <Row style={{
             marginTop: 10,
             height: 350,
             background: "rgba(216,216,216,0.48)",
             boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
             borderRadius: 5,
             backdropFilter: "blur(5px)",
             padding: 20,
             margin:5
        }}>
            <Col md="10">
            {
    isRegisterationCompleted ? (
              <center>
                <div>
                  <img style={{ width: 130 }} src={challenge_icon} />
                  <p
                    style={{
                      color: "#094709",
                      fontSize: 18,
                      marginTop: 10,
                      marginLeft: 15,
                    }}
                  >
                    Your Registeration is Complete!!
                  </p>
                  <Button
                    className="submitButton"
                    style={{ marginTop: 10 }}
                    onClick={authPage}
                  >
                    Sign In Now
                  </Button>
                </div>
              </center>
            ) :
            <Form autoComplete="off">
    <FormGroup>
      <Input
        className="inputBox"
        style={{ marginTop: 35 }}
        onChange={(e) => setRegisterationPassword(e.target.value)}
        placeholder="Enter Password"
        autoComplete="off"
      />
      <Input
        className="inputBox"
        type="password"
        style={{ marginTop: 35 }}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirm Password"
        autoComplete="off"
      />
    </FormGroup>
    <center>

    <Button
      className="submitButton"
      style={{ marginTop: 32 }}
      onClick={() => completeRegisteration()}
    >
      {
        appInfo.loading? "Loading..." :
        "Complete Registeration"
      }
       
    </Button>
    {error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                    ) : null}
    </center>
   

  </Form>
}
            </Col>
            
        </Row>
    </Container>
  )
}

export default PasswordMob