import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/logos/Header Logo.png";
import languageIcon from "../../assets/Icons/language.png";
import downIcon from "../../assets/Icons/down.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import logoutIcon from '../../assets/Icons/logout.png'
import "./Header.css";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
  Container,
  Row,
  Col,
} from "reactstrap";
function Header() {
    const [isOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleLogout =() =>{
      dispatch({ type: "LOG_OUT"});
      navigate("/mobile-auth")
    }
    return (
      <div>
        <Container style={{ paddingLeft: 0, paddingRight: 0,boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px' }}>
          <Navbar
            style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0 }}
            expand="md"
          >
            <NavbarBrand  style={{ paddingLeft: 0 }}>
              {/* <Link to='/'> */}
                <img src={logo} className="headerLogo" />
              {/* </Link> */}
            </NavbarBrand>
            <NavbarToggler
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <Collapse isOpen={isOpen} navbar style={{justifyContent:'flex-end'}}>
              <Nav className="mr-auto" navbar>
              <NavItem>
                      <NavLink>
                        <Link to='/' style={{textDecoration:'none',color:'#2d4f2d'}} >
                        Home
                        </Link>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>
                        <Link style={{textDecoration:'none',color:'#2d4f2d'}} >
                        My Resources
                        </Link>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>
                        <Link style={{textDecoration:'none',color:'#2d4f2d'}} >
                        My Alerts
                        </Link>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>
                        <Link style={{textDecoration:'none',color:'#2d4f2d'}} >
                        <img style={{width:20}} onClick={handleLogout} src={logoutIcon} />
                        </Link>
                      </NavLink>
                    </NavItem>
                 
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
  
export default Header