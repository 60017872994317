import { getProfile, updateProfile, uploadProfileImage } from "../services/ProfileServices";


export const handleUpdateProfile = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return updateProfile(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "UPDATE_PROFILE", payload: response });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleUpdateProfileImage = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return uploadProfileImage(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "USER_IMAGE_UPDATE", payload: response });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleGetProfile = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getProfile(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "UPDATE_USER_INFO", payload: response });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        callback(err?.response?.data);
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        // dispatch({ type: "SHOW_ERROR", payload:err.response.data});
      });
  };

  