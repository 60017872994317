import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import XMLParser from 'react-xml-parser';
import moment from 'moment';
import {
  Table,
  Tooltip,
    Container,
    Row,
    Col,
  } from "reactstrap";
import { handleGetAppUserSubmissionAttachmentsPreview } from '../../../store/actions/odkAction';

function HimachalTreeSpecies({userSubmission,submissionInstanceId,selectedForm}) {
    const dispatch =useDispatch();
    const [attachmentData,setAttachmentData] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
  
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const appInfo = useSelector(state=>state.appInfo);
    useEffect(()=>{
      getAppuserSubmissions()
    },[])
    const getAppuserSubmissions = () => {
      let data = {
        "projectId":1,
        "formId":selectedForm.formId,
        "instanceId":submissionInstanceId,
      };
      dispatch(
        handleGetAppUserSubmissionAttachmentsPreview(data, (args) => {
          console.log("ARGS->", args);
          setAttachmentData(args)
        })
      );
    }
    var xml = new XMLParser().parseFromString(userSubmission); 
    console.log(xml?.children)
    return (
      <Container style={{marginTop:50}}>
      <Row>
        <Col>
        <a>
         <p style={{fontSize:14}}>species type : {xml?.children[0]?.value}</p>
         </a>
          
         <p style={{fontSize:14}}>{xml?.children[0]?.value} species name : {xml?.children[3]?.value}</p>
         <p style={{fontSize:14}}>Attachments: </p>
         {
          attachmentData ?
          attachmentData.map((attachment)=>{
            return (
              <>
              <img style={{marginLeft:10}} src={attachment.value} alt={attachment.name}/>
              </>
            )
          })
          :
          !appInfo.loading ?
            <p style={{fontSize:14}}>No attachments found!</p>
            : <p style={{fontSize:14}}>Loading...</p>
         }
         
        </Col>
      </Row>
      </Container> 
    )
}

export default HimachalTreeSpecies