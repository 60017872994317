import React,{useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/logos/Header Logo.png";
import languageIcon from "../../assets/Icons/language.png";
import { useDispatch,useSelector } from 'react-redux';
import logoutIcon from '../../assets/Icons/logout.png'
import downIcon from "../../assets/Icons/down.png";
import { Link } from "react-router-dom";
import "./Header.css";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
  Container,
  Row,
  Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function Header() {
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const user = useSelector(state=>state.userInfo)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    

    window.addEventListener('resize', handleResize);
  })
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch()
    const handleLogout =() =>{
      dispatch({ type: "LOG_OUT"});
    }
  const navLinkItem = [
    {
      navLinkName: "Home",
      targerRef: "/",
    },
    {
      navLinkName: "Need Discovery",
      targerRef: "/need-discovery",
    },
    {
      navLinkName: "Science",
      targerRef: "/science",
    },
    {
      navLinkName: "My nCount",
      targerRef: "/Dashboard",
    },
    {
      navLinkName: "Engagements",
      targerRef: "/engagements",
    },
    {
      navLinkName: "About Us",
      targerRef: "/about",
    },
    {
      navLinkName: "Play for Good",
      targerRef: "#",
    },
  ];
  return (
    <div>
      <Container className="topHeader" fluid="md" style={{}}>
        <Row style={{ alignItems: "center" }}>
          <Col md="6" style={{ paddingTop: 0 }}>
            <p className="topHeaderLeftSection" style={{ paddingBottom: 0 }}>
              
                <span><a href="https://forestgovernance.in/" target="_blank" style={{textDecoration:'none',color:'black',marginRight:5}}>Intiative on Forest Economy</a></span> | <span><a href="https://www.isb.edu/en/research-thought-leadership/research-centres-institutes/bharti-institute-of-public-policy.html" target="_blank" style={{textDecoration:'none',color:'black',marginLeft:5}}>Bharti Institute of Public Policy</a></span>
               
            </p>
          </Col>
          <Col md="6">
            <div className="topHeaderRightSection">
              {user.authToken?
                 <>
                 <Link
                 style={{ textDecoration: "none" }}
                 to="/auth"
                 state={{ register: true }}
               >
                 <p
                   style={{
                     borderRadius: 3,
                     cursor: "pointer",
                     marginTop: 3,
                     color:"#124912"
                   }}
                 >
                    
                     Welcome, {user.name}
                  
                 </p>
               </Link>
               {/* <Button size='sm' style={{backgroundColor:'#124912',fontSize:12}}>Create Account</Button> */}
               {/* <Button size='sm' style={{backgroundColor:'#aa8d27',fontSize:12,paddingTop:0}}>Sign in</Button> */}
               <Link
                 style={{ textDecoration: "none" }}
                 to="/auth"
                 state={{ register: false }}
               >
                 <p
                   style={{
                     backgroundColor: "#aa8d27",
                     borderRadius: 3,
                     cursor: "pointer",
                     marginTop: 3,
                   }}
                 >
                   <a
                     style={{
                       color: "white",
                       fontSize: 11,
                       paddingLeft: 10,
                       paddingRight: 10,
                       paddingTop: 2,
                       paddingBottom: 2,
                       fontWeight: 400,
                     }}
                   >
                    Dashboard
                   </a>
                 </p>
               </Link>
               </>
                :
                <>
                
                {/* <Link
                style={{ textDecoration: "none" }}
                to={windowSize.width<400?'/mobile-register':'/auth'}
                state={{ register: true }}
              >
                <p
                  style={{
                    backgroundColor: "#124912",
                    borderRadius: 3,
                    cursor: "pointer",
                    marginTop: 3,
                  }}
                >
                  <a
                    style={{
                      color: "white",
                      fontSize: 11,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      fontWeight: 400,
                    }}
                  >
                    Create Account
                  </a>
                </p>
              </Link> */}
              {/* <Button size='sm' style={{backgroundColor:'#124912',fontSize:12}}>Create Account</Button> */}
              {/* <Button size='sm' style={{backgroundColor:'#aa8d27',fontSize:12,paddingTop:0}}>Sign in</Button> */}
              <Link
                style={{ textDecoration: "none" }}
                to={windowSize.width<500?'/mobile-auth':'/auth'}
                state={{ register: false }}
              >
                <p
                  style={{
                    backgroundColor: "#aa8d27",
                    borderRadius: 3,
                    cursor: "pointer",
                    marginTop: 3,
                  }}
                >
                  <a
                    style={{
                      color: "white",
                      fontSize: 11,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      fontWeight: 400,
                    }}
                  >
                    Sign in
                  </a>
                </p>
              </Link>
              </>
              }
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="lang">
        <p style={{ marginTop: 4 }}>
                <span>
                  <img
                    style={{
                      width: 20,
                      color: "#124912",
                      marginBottom: 3,
                      cursor: "pointer",
                    }}
                    src={languageIcon}
                  />
                </span>
                Language
                <span>
                  <img style={{ width: 15, marginBottom: 1 }} src={downIcon} />
                </span>
              </p>
        </DropdownToggle>
        <DropdownMenu >
           
          <DropdownItem className="langItem">English</DropdownItem>
           
        </DropdownMenu>
      </Dropdown>
              {
                user.authToken?
                <Link
                style={{ textDecoration: "none" }}
               onClick={handleLogout}
                state={{ register: false }}
              >
                <p
                  style={{
                   color: "white",
                   fontSize: 11,
                   paddingLeft: 5,
                   paddingRight: 10,
                   paddingTop: 2,
                   paddingBottom: 2,
                   fontWeight: 400,
                 }}  
                >
                 
                  <img style={windowSize.width<778?{height:13,marginTop:6}:{height:17}} src={logoutIcon} />
                </p>
              </Link>
              :null
              }
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Navbar
          style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0 }}
          expand="md"
        >
          <NavbarBrand style={{ paddingLeft: 0 }}>
            <img src={logo} className="headerLogo" />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {navLinkItem.map((navItem) => {
                return (
                  <NavItem>
                    <NavLink>
                      <Link style={{textDecoration:'none',color:'#2d4f2d'}} to={navItem.targerRef}>
                      {navItem.navLinkName}
                      </Link>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Header;
