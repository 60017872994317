import React,{useEffect,useState} from 'react'
import './HeroSection2.css'
import challenge_icon from '../../assets/Icons/challenge_icon.png'
import userLeafIcon from '../../assets/Icons/Leaf.png'
import userIcon from '../../assets/Icons/userIcon.png'
import axios from 'axios';
import moment from 'moment'
import {
    Container,Row,Col,Button
} from 'reactstrap'

function HeroSection2() {
  const [liveFeedData,setLiveFeedData] = useState([]);
  const [loading,setLoading] = useState(false);
 useEffect(()=>{
 getFeed()
 },[])
  const getFeed = async() =>{
    setLoading(true)
    await axios
    .get(`https://d4pnwqybwh.execute-api.us-east-1.amazonaws.com/latest-contibution`)
    .then(function (response) {
      console.log(response?.data?.body)
      setLiveFeedData(response?.data?.body)
      setLoading(false)
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  }
console.log(liveFeedData)

  return (
    <>


      <Container className='mainContainer'>
      <Row>
      <Col className="column1" md="8" style={{padding:20}}  >
      <center><p style={{color:'white'}}>Live Feed</p></center>
      <div className="liveFeed" style={{marginTop:-10,paddingBottom:10}}>
        
      {/* <center><p style={{color:'white'}}>Live Feed</p></center> */}
       {
         loading &&
         <center><p style={{color:'white'}}>Loading...</p></center>
        }
      { liveFeedData!==null &&
        liveFeedData?.map((feed,key)=>{
          console.log(feed)
          return(
            <>
              <p style={{fontSize:14,color:'white',marginBottom:5,marginTop:8}}>
             Data collected by User {feed.submitterName} on {moment(feed?.submissionDate).format('Do MMMM YYYY, h:mm a')} 
              </p>
              <hr style={{height:1,color:'white',margin:0,marginTop:3}}/>
            </>
          )
        })
      }
        </div>
        </Col>
        {/* <Col className="column" md="4" style={{padding:20}}  >
            <center><p style={{color:'white'}}>BioGuard Barvos</p></center>
            <p>
              <Container>

            <Row style={{display:'flex',alignItems:'center',justifyContent:'center',justifyItems:'center'}}>
              <Col md="3" xs="3" style={{paddingLeft:0}}>
              <div>
              <img style={{height:36,width:30,paddingLeft:0,paddingRight:0}} src={userLeafIcon} /> 
              <img className='userIcon' src={userIcon} />

              </div>
              </Col>
              <Col md="3" xs="3" style={{padding:0}}>
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>111 contributions</p>
              </Col>
              <Col md="3" xs="3">
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>9 Species</p>
              </Col>
              <Col md="3" xs="3" style={{padding:0}}>
              <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>11 Eco-regions</p>
              </Col>
            </Row>
              </Container>
              <hr style={{height:1.5,color:'#0a3b0a',margin:0,marginTop:10}}/> 
            </p>
            <p>
              <Container>

            <Row style={{display:'flex',alignItems:'center',justifyContent:'center',justifyItems:'center'}}>
              <Col md="3" xs="3" style={{paddingLeft:0}}>
  
              <div>
              <img style={{height:36,width:30,paddingLeft:0,paddingRight:0}} src={userLeafIcon} /> 
              <img className='userIcon' src={userIcon} />

              </div>
              </Col>
              <Col md="3" xs="3" style={{padding:0}}>
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>111 contributions</p>
              </Col>
              <Col md="3" xs="3">
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>9 Species</p>
              </Col>
              <Col md="3" xs="3" style={{padding:0}}>
              <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>11 Eco-regions</p>
              </Col>
            </Row>
              </Container>
              <hr style={{height:1.5,color:'#0a3b0a',margin:0,marginTop:10}}/> 
            </p>
            <p>
              <Container>

            <Row style={{display:'flex',alignItems:'center',justifyContent:'center',justifyItems:'center'}}>
              <Col md="3" xs="3" style={{paddingLeft:0}}>
              <div>
              <img style={{height:36,width:30,paddingLeft:0,paddingRight:0}} src={userLeafIcon} /> 
              <img className='userIcon' src={userIcon} />

              </div>
              </Col>
              <Col md="3" xs="3" style={{padding:0}}>
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>111 contributions</p>
              </Col>
              <Col md="3" xs="3">
             <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>9 Species</p>
              </Col>
              <Col md="3"  xs="3" style={{padding:0}}>
              <p style={{fontSize:11,fontWeight:200,marginTop:0,marginBottom:0}}>11 Eco-regions</p>
              </Col>
            </Row>
              </Container>
              <hr style={{height:1.5,color:'#0a3b0a',margin:0,marginTop:10}}/> 
            </p>

        </Col>
          <Col className="column1" md="4" style={{padding:20}}>
            <center><p style={{color:'white'}}>Live Feed</p></center>
              <p style={{fontSize:13,color:'white',marginBottom:0,marginTop:8}}>
              5 Validation by User Nitesh on 31-01-2023 
              </p>
              <hr style={{height:1,color:'white',margin:0,marginTop:3}}/> 
              <p style={{fontSize:13,color:'white',marginBottom:0,marginTop:8}}>
              5 Validation by User Nitesh on 31-01-2023 
              </p>
              <hr style={{height:1,color:'white',margin:0,marginTop:3}}/> 
              <p style={{fontSize:13,color:'white',marginBottom:0,marginTop:8}}>
              5 Validation by User Nitesh on 31-01-2023 
              </p>
              <hr style={{height:1,color:'white',margin:0,marginTop:3}}/> 
              <p style={{fontSize:13,color:'white',marginBottom:0,marginTop:8}}>
              5 Validation by User Nitesh on 31-01-2023 
              </p>
              <hr style={{height:1,color:'white',margin:0,marginTop:3}}/> 
        </Col> */}
          <Col className="column2" md="4" style={{padding:20}}>
            <center><p style={{color:'white'}}>BioBenevolence Brigade [B3]</p></center>
            <center><img style={{width:100,marginTop:-5,marginBottom:6}} src={challenge_icon}/>
            <h5 style={{color:'white',fontWeight:300,marginTop:5}}>Gaia Challenge</h5>
            <h6 style={{color:'white',fontWeight:200,marginTop:3}}>Find the Growthlings!</h6>
            <Button style={{background:'rgb(161 130 18)',border:'none',borderRadius:3,fontWeight:200,paddingTop:2,paddingBottom:2,marginTop:2}}>Accept</Button>
            </center>

        </Col>
      </Row>
    </Container>
    </>
  )
}

export default HeroSection2