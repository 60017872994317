import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../assets/logos/White on Transparent.png";
import { Link } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import "../Login.css";
import {
    handleVerifyOtp,
  } from "../../../store/actions/authAction";

function OtpMob() {
    const dispatch = useDispatch()
    const location  = useLocation();
    const user  =location.state.user;
    const timer = useRef();  
    const [otp, setOtp] = useState(null);
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    var registerUserInfo = useSelector((state) => state.registerUserInfo);
    console.log(registerUserInfo,"registeruser info")
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
    const verifyOtp = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (otp === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            countryCode: "+91",
            phoneNumber: user.phoneNumber,
            otp: otp,
          };
          dispatch(
            handleVerifyOtp(data, (args) => {
              // setappInfo.loading(false);
              console.log("ARGS->", args);
              setOtp(null);
              navigate("/mobile-password", { state: { user: user } });
            //   mobile-password
              // setRegisterationStarted(true)
            })
          );
        }
      };
  return (
    <Container className="bgImg">
        <Row>
        <center>
            <Link to="/">
              <img style={{ width: 250,marginBottom:20,marginTop:40 }} src={whiteLogo} />
            </Link>
          </center>
        </Row>
        <Row style={{
             marginTop: 10,
             height: 350,
             background: "rgba(216,216,216,0.48)",
             boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
             borderRadius: 5,
             backdropFilter: "blur(5px)",
             padding: 20,
             margin:5
        }}>
            <Col md="10">
            <Form>
                <FormGroup>
                  <Input
                    className="inputBox"
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ marginTop: 35 }}
                    placeholder="Please Enter OTP"
                    autoComplete="off"
                  />
                </FormGroup>
                <center>

                <Button
                  className="submitButton"
                  style={{ marginTop: 32 }}
                  onClick={() => verifyOtp()}
                >
                  {
                    appInfo.loading?"Loading..." : "Submit OTP"
                  }
                   
                </Button>

                {error !== "" ? (
                    <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                    ) : null}
                </center>
              </Form>
    
            </Col>
        </Row>
    </Container>
  )
}

export default OtpMob