import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import XMLParser from 'react-xml-parser';
import moment from 'moment';
import {
  Table,
  Tooltip,
    Container,
    Row,
    Col,
  } from "reactstrap";
import { handleGetAppUserSubmissionAttachmentsPreview } from '../../../store/actions/odkAction';
function NcountTreeInventoryOrissa({userSubmission,submissionInstanceId,selectedForm}) {
  const dispatch =useDispatch();
  const [attachmentData,setAttachmentData] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const appInfo = useSelector(state=>state.appInfo);
  useEffect(()=>{
    getAppuserSubmissions()
  },[])
  const getAppuserSubmissions = () => {
    let data = {
      "projectId":1,
      "formId":selectedForm.formId,
      "instanceId":submissionInstanceId,
    };
    dispatch(
      handleGetAppUserSubmissionAttachmentsPreview(data, (args) => {
        console.log("ARGS->", args);
        setAttachmentData(args)
      })
    );
  }
  var xml = new XMLParser().parseFromString(userSubmission); 
  console.log(xml?.children)
  return (
    <Container style={{marginTop:50}}>
    <Row>
      <Col>
      <a id="PointTooltip">
       <p style={{fontSize:14}}>Tree Location : POINT ({xml?.children[0]?.value})</p>
       </a>
       <Tooltip
        isOpen={tooltipOpen}
        target="PointTooltip"
        toggle={toggle}
        style={{fontSize:11,backgroundColor:'rgb(4, 31, 4)'}}
      >
        latitude, longitude, altitude, accuracy
      </Tooltip>
       <p style={{fontSize:14}}>Tree species : {xml?.children[3]?.value}</p>
       <p style={{fontSize:14}}>Attachments: </p>
       {
        attachmentData ?
        attachmentData.map((attachment)=>{
          return (
            <>
            <img style={{marginLeft:10}} src={attachment.value} alt={attachment.name}/>
            </>
          )
        })
        :
        !appInfo.loading ?
          <p style={{fontSize:14}}>No attachments found!</p>
          : <p style={{fontSize:14}}>Loading...</p>
       }
       
      </Col>
    </Row>
    </Container> 
  )
}

export default NcountTreeInventoryOrissa