import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Login.css";
import { handleForgetPassword } from "../../../store/actions/authAction";
function ForgetPassword({updateParentState}) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState("");
    var appInfo = useSelector((state) => state.appInfo);
    const dispatch = useDispatch();
    const timer = useRef();  
    const navigate= useNavigate()
    const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      const handleClick = () => {
        updateParentState({
            screenNumber:2,
            phoneNumber:phoneNumber
        });
      };



      const forgetPassword = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (phoneNumber.length !== 10) {
          setError("*Phone number should contain only 10 digits!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let data = {
            countryCode: "+91",
            phoneNumber: phoneNumber,
          };
          dispatch(
            handleForgetPassword(data, (args) => {
              // setappInfo.loading(false);
              handleClick()
              console.log("ARGS->", args);
              
            //   mobile-password
              // setRegisterationStarted(true)
            })
          );
        }
      };
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
         
    <Row>
        <Col md="12">
        <Form>
            <FormGroup>
              <Input
                className="inputBox"
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{ marginTop: 35 }}
                placeholder="Please Enter Mobile Number"
                autoComplete="off"
                value={phoneNumber}
              />
            </FormGroup>
            <center>

            <Button
              className="submitButton"
              style={{ marginTop: 32 }}
              onClick={forgetPassword}
            >
              {
                appInfo.loading?"Loading..." : "Send OTP"
              }
               
            </Button>

            {error !== "" ? (
                <p style={{ color: "#9e1212", marginTop: 10 }}>{error}</p>
                ) : null}

            </center>
<Button
              className="submitButton"
              style={{ marginTop: 32,color:'#76561c',background:'white',fontSize:13 }}
              onClick={()=>updateParentState({
                screenNumber:0,
                phoneNumber:phoneNumber
              })}
            >
              Signin Now
            </Button>

          </Form>

        </Col>
    </Row>
</Container>
  )
}

export default ForgetPassword