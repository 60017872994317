import React from 'react'
import icon from '../../assets/Icons/instructions.png'
import './Profile.css'
import { useDispatch, useSelector } from "react-redux";
import {
  Button
} from "reactstrap";
function Instructions({tabId,setActiveTab}) {
  const user = useSelector((state) => state.userInfo);
    console.log(tabId,setActiveTab)
    const setActiveTabId = () =>{
        setActiveTab("2")
    }
  return (
    <>
    <p>Please follow the steps below to get started on your nCount journey!</p>
    <br></br>
    <div style={{display:'flex'}}>
    <img src={icon}   className="instructIcon"/>
    <p className='instruct'>For systematic data collection, nCount uses <span style={{fontWeight:500,}}><a style={{textDecoration:'none',color:'black'}} href='https://getodk.org/' target='_blank'>Open Data Kit</a></span> (ODK) framework.
    </p>
    </div>
    <div style={{display:'flex'}}>
    <img src={icon} className="instructIcon"/>
    <p className='instruct'> Please generate your ODK Collect access code by clicking on <span style={{fontWeight:500,color:'black'}}>“Generate Access Code”</span> tab available below your profile details.
    </p>
    
    </div>
    <div style={{display:'flex'}}>
    <img src={icon} className="instructIcon"/>
    <p className='instruct'> After generating your access code, you have the option to select specific data collection campaigns and begin contributing.
    </p>
    
    </div>
    <div style={{display:'flex'}}>
    <img src={icon} className="instructIcon"/>
    <p className='instruct'>You can access all the active campaigns from the “Data Collection” tab. Click <span style={{color:'red',fontWeight:500,cursor:'pointer'}} onClick={()=>user?.odkUserId?setActiveTabId():null}>here</span> for access.
    </p>
    </div>
    <div style={{display:'flex'}}>
    <img src={icon} className="instructIcon"/>
    <div>

    <p className='instruct'>You can refer our support documentation page for detailed instruction on getting started with data collection. 
    </p>
    <Button   style={{fontSize:14,borderRadius:7,marginTop:-5,marginBottom:10,background:'#c0810f'}} size="sm" className="submitButton">
                  <a href='https://docs.ncount.in' target='_blank' style={{textDecoration:'none',color:'white'}}>nCount Support</a>
                </Button>
    </div>
    </div>
  
    <div style={{display:'flex'}}>
    <img src={icon} className="instructIcon"/>
    <div>

    <p className='instruct'>You can post your questions, issues, and feedback on our dedicated discussion forum. We will respond as soon as possible. 
    </p>
    <Button style={{fontSize:14,borderRadius:7,marginTop:-5,marginBottom:10,background:'#c0810f'}} size="sm" className="submitButton">
                 <a href='https://forum.ncount.in' target='_blank' style={{textDecoration:'none',color:'white'}}>Discussion Forum</a> 
                </Button>
    </div>
    
    </div>
    </>
  )
}

export default Instructions