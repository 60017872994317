import React,{useState,useEffect} from 'react'
import findTreeIcon from '../../assets/Icons/findtree.png'
import insightsIcon from '../../assets/Icons/insights.png'
import collaborateIcon from '../../assets/Icons/collaborate.png'
import dnaImage from '../../assets/Icons/eDNA.png'
import openSourceImage from '../../assets/Icons/open.png'
import gameImage from '../../assets/Icons/game.png'
import './HeroSection3.css'
import {
    Container,Col,Row
} from 'reactstrap'
function HeroSection3() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log (windowSize)
  return (
    <Container style={{marginTop:25}}>
        <center><p className='containerHeading'>nCount Feature Universe</p></center>
      <Row>
        <Col md="4" sm="6" xs="6" style={{marginTop:10}}>
           <center><img className='imageGroup' src={findTreeIcon}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'50%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
            A new way of conducting the Forest Inventory
          </p>
           </center> 
        </Col>
        <Col md="4" sm="6" xs="6"  style={{marginTop:10}}>
        <center><img className='imageGroup' src={collaborateIcon}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'50%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
           Get scientific insights from your contributions
           </p>
           </center> 
        </Col>
        <Col md="4" sm="6" xs="6"  style={{marginTop:10}}>
        <center><img className='imageGroup' src={insightsIcon}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'60%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
            Intelligent analytical engine for knowledge discovery
           </p>
           </center> 
        </Col>
        {
          windowSize.width>779 ?
          <p style={{marginTop:30}}></p>
          
          :null

        }
        <Col md="4" sm="6" xs="6" style={{marginTop:10}}>
           <center><img className='imageGroup' src={dnaImage}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'80%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
            Open eDNA repository and analytics for rapid biodiversity monitoring
          </p>
           </center> 
        </Col>
        <Col md="4" sm="6" xs="6"  style={{marginTop:10}}>
        <center><img className='imageGroup' src={gameImage}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'80%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
           Smart Spatially Aware Gaming Challenges for biodiversity monitoring
           </p>
           </center> 
        </Col>
        <Col md="4" sm="6" xs="6"  style={{marginTop:10}}>
        <center><img className='imageGroup' src={openSourceImage}/>
           <p style={windowSize.width>779?{marginTop:10,fontSize:14,fontWeight:500,width:'80%'}:{marginTop:10,fontSize:12,fontWeight:500,width:'100%'}}>
            Always Free and Open Source
           </p>
           </center> 
        </Col>
      </Row>
    </Container>
  )
}

export default HeroSection3