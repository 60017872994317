import React, { useState,useEffect } from 'react'
import Header from "../Header/Header";
import Hero from "../Hero Section/Hero";
import HeroSection2 from "../HeroSection2/HeroSection2";
import HeroSection3 from "../HeroSection3/HeroSection3";
import HeroSection4 from "../HeroSection4/HeroSection4";
import Footer from "../Footer/Footer";
import spinner from '../../assets/Icons/Loader.gif'
function Home() {
  const [loading,setLoading] = useState(true);
  useEffect(()=>{
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 100);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  },[])
  console.log(loading,"loading-----------------")
  return (
    <>
    {
      loading?
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <img src={spinner} alt="Loading..." />

      </div>
      :
      <>
      <Header/> 
      <Hero/> 
      <HeroSection2/> 
      <HeroSection3/> 
      <HeroSection4/> 
      <Footer/>
      </> 
    }
    </>
    
  )
}

export default Home