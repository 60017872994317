import React, { useState } from 'react'
import rightHeroImage from '../../assets/Images/bghighres.jpg'
import image2 from '../../assets/Images/Home-Slider/image2.png'
import image3 from '../../assets/Images/Home-Slider/image3.png'
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Hero.css'
import { 
  Container,
  Row,
  Col,Button
} from 'reactstrap'; 
import spinner from '../../assets/Icons/Loader.gif'
function Hero() {
  const [loading,setLoading] = useState(true);
 

  const imageLoad = () =>{
    setLoading(false)
  }
  console.log(loading,"loading......")
  return (
    <div>
      {
        loading?
        <p style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}><img src={spinner}/></p>
        :null
      }
      <Container>
        <Row>
          
            <Col md="4" className='HeroSectionLeft' style={loading?{display:'none'}:null}>
               
               <div style={{display:'flex'}}>
                <div>

                <h1 className='heroSectionText'>
                    <span style={{fontSize:75,fontWeight:500}}>U Count as </span><br></br> <span style={{fontSize:47,fontWeight:200,}}>We Count on <span style={{fontWeight:400}}>U!</span></span>
                </h1>
                <br></br>
       
                <div>
                  <Link to='/about'>
               <Button className='heroButtons' style={{background:'rgb(170, 141, 39)',border:'none',marginRight:10,fontWeight:300,borderRadius:3}}>Learn More</Button>
                  </Link>
                  <Link to='/auth'>
               <Button className='heroButtons' style={{background:'rgb(170, 141, 39)',border:'none',borderRadius:3}}>Begin Your Journey</Button>
                  </Link>
                </div>
                </div>
               </div>
 
            </Col>
            <Col md="8" className='HeroSectionRight'>
            <Carousel  autoPlay showIndicators={false} showArrows={false}  infiniteLoop showStatus={false} showThumbs={false}>
                <div>
                    <img src={rightHeroImage}  style={{height:360,objectFit:'cover'}}/>
                    
                </div>
                <div>
                    <img src={image2} style={{height:360,objectFit:'cover'}}/>
                    
                </div>
                <div>
                    <img src={image3} style={{height:360,objectFit:'cover'}}/>
                    
                </div>
            </Carousel>
        <img  style={{display:'none'}} onLoad={imageLoad} src={rightHeroImage} />
            </Col>
        </Row>
        </Container>  
    </div>
  )
}

export default Hero