const INIT_STATE = {
  userId: null,
  name: null,
  phoneNumber: null,
  email: null,
  password: null,
  authToken: null,
  userImageUri: null,
  organization: null,
  organizationName: null,
  webURL:null,
  address: null,
  feedback: null,
  odkToken:null,
  odkUserId:null,
  odkFormAccess:[],
  contributions:0
};



const userInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      console.log("TALK IS CHEAP SHOW ME THE PAYLOAD", action.payload);
      return {
        ...state,
        name: action.payload.user.name,
        phoneNumber: action.payload.user.phoneNumber,
        email: action.payload.user.email,
        password: action.payload.user.password,
        userId: action.payload.user.id,
        authToken: action.payload.token,
        userImageUri: action.payload.user.userImageUri,
        organization: action.payload.user.organization,
        organizationName: action.payload.user.organizationName,
        webURL: action.payload.user.webURL,
        address: action.payload.user.address,
        feedback: action.payload.user.feedback,
        odkToken:action.payload.odkInfo.odkToken,
        odkUserId:action.payload.user.odkUserId,
        odkFormAccess:action.payload.odkInfo.allFormAccess
      };
    case "USER_IMAGE_UPDATE":
      console.log("TALK IS CHEAP SHOW ME THE PAYLOAD", action.payload);
      return {
        ...state,
        userImageUri: action.payload,
      };
    case "UPDATE_PROFILE":
      console.log("TALK IS CHEAP SHOW ME THE PAYLOAD", action.payload);
      return {
        ...state,
        organization: action.payload.organization,
        organizationName: action.payload.organizationName,
        address: action.payload.address,
        feedback: action.payload.feedback,
        email:action.payload.email
      };
      case "UPDATE_ODK_USER":
        return{
            ...state,
            odkToken:action.payload.token,
            odkUserId:action.payload.id
        };
      case "UPDATE_ODK_FORM_ACCESS":
        return {
            ...state,
            odkFormAccess:action.payload
        }
     
    case "LOG_OUT":
      return {
        ...state,
        userId: null,
        name: null,
        phoneNumber: null,
        email: null,
        password: null,
        authToken: null,
        userImageUri: null,
        organization: null,
        organizationName: null,
        webURL: null,
        address: null,
        feedback: null,
        odkToken:null,
        odkUserId:null
      };
      case "UPDATE_USER_INFO":
      console.log("TALK IS CHEAP SHOW ME THE PAYLOAD", action.payload);
      return {
        ...state,
        name: action.payload.user.name,
        phoneNumber: action.payload.user.phoneNumber,
        email: action.payload.user.email,
        password: action.payload.user.password,
        userId: action.payload.user.id,
        userImageUri: action.payload.user.userImageUri,
        organization: action.payload.user.organization,
        organizationName: action.payload.user.organizationName,
        webURL: action.payload.user.webURL,
        address: action.payload.user.address,
        feedback: action.payload.user.feedback,
        odkToken:action.payload.odkInfo.odkToken,
        odkUserId:action.payload.user.odkUserId,
        odkFormAccess:action.payload.odkInfo.allFormAccess
      };
      case "UPDATE_CONTRIBUTIONS": 
       return {
        ...state,
        contributions:action.payload
       }
    default:
      return state;
  }
};

export default userInfo;
