import React, { useState, useRef, useEffect } from "react";
import { Container, Input, Form, FormGroup, Label, Button,Col,Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    handleLoginUser,
  } from "../../../store/actions/authAction";
  import { useNavigate } from "react-router-dom";
import "../Login.css";
function AuthLogin({updateParentState}) {
    const timer = useRef();
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch()
    var appInfo = useSelector((state) => state.appInfo);
    var registerUserInfo = useSelector((state) => state.registerUserInfo);
   
    const navigate= useNavigate()
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
      
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      const resetError = () => {
        setError("");
        clearTimeout(timer);
      };
      useEffect(()=>{
        if(windowSize.width>=778){
            navigate("/auth");
        }

      },[windowSize.width])
      useEffect(()=>{
        if (appInfo.error!==null) {
          const timeoutId = setTimeout(() => {
            dispatch({type:'REMOVE_ERROR'})
          }, 1000);
    
          return () => clearTimeout(timeoutId);
        }
      },[appInfo.error])
    const handleLogin = () => {
        // setappInfo.loading(true);
        console.log("function login called");
        if (phoneNumber === null || password === null) {
          // setappInfo.loading(false);
          setError("*Please Fill All the Fields!!");
          timer.current = setTimeout(resetError, 1500);
        } else {
          let user = {
            phoneNumber: phoneNumber,
            password: password,
          };
          dispatch(
            handleLoginUser(user, (args) => {
              
              console.log("ARGS->", args);
              // setappInfo.loading(false);
              if (!args.user.id) {
                setError(appInfo.error);
                console.log("errorrrrrrrrrrrrrrrr");
              }
              else{
                // setappInfo.loading(false);
                navigate("/dashboard");
              }
            })
          );
        }
      };
      const handleClick = () => {
        // Update state value in parent component
        updateParentState(1);
      };
  return (
    <Container style={{paddingRight:0,paddingLeft:0}}>
    
        <Row>
            <Col md="12">
    <Form style={{ marginTop: 40 }}>
    <FormGroup style={{display:'flex',flexDirection:'column'}}>
      <input
        className="inputBox"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Email ID/ Mobile Number"
        type="email"
      />
      <input
        className="inputBox"
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        style={{ marginTop: 35 }}
        placeholder="Password"
        autoComplete='false'
      />
    </FormGroup>
     <center>
     <Button
      className="submitButton"
      style={{ marginTop: 32 }}
      onClick={() => handleLogin()}
    >
      {" "}
      {
        appInfo.loading?"Loading..." :"Sign In"
      }
       
    </Button>
<p style={{marginTop:20,color:'#76561c',cursor:'pointer',width:150,padding:3,borderRadius:25,background:'white',fontSize:13}} onClick={()=>handleClick()}> Forgot Password?</p>
    {error !== "" ? (
      <p style={{ color: "#9e1212", marginTop: -10 }}>{error}</p>
      ) : null}

    {appInfo.error !== "" ? (
      <p style={{ color: "#9e1212", marginTop: -10 }}>
        {appInfo.error}
      </p>
    ) : null}
    </center>

  </Form>
            </Col>
           
        </Row>
    </Container>
  )
}

export default AuthLogin